.hidden {
  display: none !important;
}

/* ––––––––––––––––––––––––––––––– */

.clearfix {
  &:before, &:after {
    content: '';
    display: table;
  }
  &:after {
    clear: both;
  }
}

/* ––––––––––––––––––––––––––––––– */

.errortext {
  color: #f86973;
}

/* ––––––––––––––––––––––––––––––– */

.text {
  &--inline {
    display: inline-block;
  }
}

/* ––––––––––––––––––––––––––––––– */

.helper {
  &--margin-bottom {
    margin-bottom: 40px;
  }
  &--big-margin-bottom {
    margin-bottom: 100px;
  }
}
@media(max-width: 767px) {
  .helper {
    &--margin-bottom {
      margin-bottom: 20px;
    }
    &--big-margin-bottom {
      margin-bottom: 50px;
    }
  }
}

/* ––––––––––––––––––––––––––––––– */

.column {
  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    max-width: 774px;
  }
  & {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    flex: 1;
    margin-left: 26px;
    &:first-child {
      margin-left: 0;
    }
  }
  &__row {
    display: flex;
    flex-direction: row;
  }

  &__wrapper > &__row {
    width: 100%;
    .form-row + .form-row {
      margin-left: 26px;
    }
  }
}
/* –––––––––––––––––––––––––––– */
/* –––––––––– TABLET –––––––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 1023px) {
  .column {
    &__wrapper {
      max-width: 670px;
    }
  }
}


/* –––––––––––––––––––––––––––– */
/* –––––––––– MOBILE –––––––––– */
/* –––––––––––––––––––––––––––– */
@media(max-width: 767px) {
  .column {
    width: 100%;
    margin-left: 0;
    &__wrapper {
      flex-direction: column;
    }
    &__row {
      flex-direction: column;
    }
    &__wrapper > &__row {
      min-width: 100%;
      .form-row + .form-row {
        margin-left: 0;
      }
    }
  }
}

/* ––––––––––––––––––––––––––––––– */
