.order-details {


  &__summ {
    color: #545454;
    font-size: 13px;
    margin-top: -5px;
    padding-left: 6px;


    tr:last-child {
      td {
        font-weight: 700;
        &:first-child {
          text-transform: uppercase;
        }
      }
    }
    td {
      padding: 7px 0;
      vertical-align: top;
      white-space: nowrap;

      &:last-child {
        padding-left: 25px;
      }
    }
  }

  &__info {
    color: #545454;
    font-size: 13px;

    td {
      padding: 5px 0;
      vertical-align: top;
      &:first-child {
        white-space: nowrap;
      }
      &:last-child {
        padding-left: 10px;
      }
    }

    .order-status {
      margin-left: -7px;
    }
  }
}


/* –––––––––––––––––––––––––––– */
/* ––––––– SMALL DESKTOP –––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 1279px) {

  .order-details {
    &__summ {
      margin-top: -8px;
      padding-left: 62px;
    }
  }

}


/* –––––––––––––––––––––––––––– */
/* –––––––––– TABLET –––––––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 1023px) {


}

/* –––––––––––––––––––––––––––– */
/* –––––––––– MOBILE –––––––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 767px) {
  .order-details {
    &__summ {
      margin-top: 30px;
      padding-left: 0;
      margin-left: -13px;
      margin-right: -13px;
      width: calc(100% + 26px);

      & td {
        width: 50%;

        &:last-child {
          padding-left: 12px;
        }
      }
    }

    &__info {
      position: relative;

      td {
        padding: 10px 0;
      }

      &:after {
        content: '';
        position: absolute;
        bottom: -10px;
        left: -30px;
        right: -30px;
        height: 1px;
        background: #eee;
      }
    }
  }
}
