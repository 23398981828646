.social-share {
  display: flex;
  position: absolute;
  transform: translate(-50%, -50%);
  margin-right: -50%;
  left: 50%;
  top: 50%;

  &__label {
    display: block;
    line-height: 30px;
  }
  &__icons-wrapper {

  }
}

/*** product card ***/
.product-info__row {
  .social-share {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    transform: translate(0,0);
    margin-right: 0;
    left: auto;
    top: auto;
    margin-top: 20px;
    padding: 18px 0;
    box-shadow: inset 0 -1px 0 0 #eee, inset 0 1px 0 0 #eee;
  }
}

/*** news/blog post ***/
.paginator {
  .social-share {
    margin-top: -1px;
    &__icons-wrapper {
      margin-left: 18px;
    }
  }
  .social__item {
    margin: 0;
  }
}
