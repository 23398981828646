.shops {
  &__wrapper {
    margin-top: 17px;
  }

  &__item {
    display: flex;
    height: 300px;
    padding: 1px;
    box-shadow: inset 0 0 0 1px #f3f3f3;
    text-decoration: none;
    margin-bottom: 45px;
    &:last-child {
      margin-bottom: 15px;
    }

    &-info {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      padding-left: 35px;
    }
    &-text {
      font-size: 13px;
      color: #545454;
      line-height: 16px;
    }
    &-icon {
      width: 36px;
      min-width: 36px;
    }
    &-image {
      height: 100%;
      background-size: cover;
    }

    &-address {
      display: flex;
      margin-bottom: 19px;

      &-icon {
        width: 22px;
        height: 16px;
        margin-left: -2px;
        margin-top: -3px;
      }
    }

    &-phone {
      display: flex;
      margin-bottom: 16px;

      &-icon {
        width: 10px;
        height: 16px;
        margin-left: 4px;
        margin-top: -8px;
      }
    }

    &-clock {
      display: flex;

      &-icon {
        width: 15px;
        height: 15px;
        margin-left: 1px;
        margin-top: -8px;
      }
    }

  }
}


/* –––––––––––––––––––––––––––– */
/* –––––––– BIG DESKTOP ––––––– */
/* –––––––––––––––––––––––––––– */
@media (min-width: 1280px) {
  .shops {
    &__item {

      &-image {
        min-width: 472px;
      }
      &-info {
        padding-right: 30px;
      }
    }
  }
}

/* –––––––––––––––––––––––––––– */
/* ––––––– SMALL DESKTOP –––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 1279px) {

  .shops {

    &__item {

      &-image {
        min-width: 460px;
      }
      &-info {
        padding-right: 60px;
      }
    }
  }

}

/* –––––––––––––––––––––––––––– */
/* –––––––––– TABLET –––––––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 1023px) {

  .shops {

    &__wrapper {
      margin-top: 22px;
    }
    &__item {
      &:last-child {
        margin-bottom: 65px;
      }

      &-image {
        min-width: 400px;
      }
      &-info {
        padding-right: 30px;
      }
    }
  }


}


/* –––––––––––––––––––––––––––– */
/* –––––––––– MOBILE –––––––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 767px) {

  .shops {

    &__wrapper {
      margin-top: 9px;
    }
    &__item {
      flex-direction: column;
      height: auto;

      &:last-child {
        margin-bottom: 55px;
      }

      &-image {
        min-width: 100%;
        height: 198px;
      }
      &-info {
        padding: 35px 29px;
      }
    }
  }

}
