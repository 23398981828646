.catalog-filter {
  display: flex;
  flex-direction: column;
  box-shadow: inset 0 1px 0 0 #f3f3f3;
  margin-top: 1px;

  & .toggle-trigger {
    cursor: pointer;
  }

  &__item {
    box-shadow: inset 0 -1px 0 0 #f3f3f3;
  }

  &__heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 11px;
    line-height: 45px;
    color: #000;
    text-transform: uppercase;
    letter-spacing: .1em;
    padding: 4px 10px 3px;
    position: relative;
  }
  &__item.active &__heading {
    font-weight: 600;
  }

  &__icon {
    width: 8px;
    height: 10px;
    fill: #9a9a9a;
    transform: rotate(180deg);
  }
  &__item.active &__icon {
    transform: rotate(0);
  }

  &__inner {
    display: none;
    padding: 11px 0 25px 10px;
    & .customscroll {
      max-height: 235px;
    }
    & .checkbox {
      margin-bottom: 15px;
    }
  }

  &--clear {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    text-align: right;
    box-shadow: none;
    padding: 15px 0;
    margin-top: 10px;
    position: relative;
    cursor: pointer;

    .clear-text,
    .hide-text {
      display: inline;
      position: relative;
      padding-left: 15px;
      color: #000;
      transition: color .35s;

      &:before {
        display: block;
        content: '';
        position: absolute;
        top: 4px;
        left: 0;
        width: 9px;
        height: 9px;
        background-repeat: no-repeat;
        background-image: svg('cross', '[fill]: #9a9a9a');
        background-size: 100%;
        transition: background-image .45s;
      }

      &:hover {
        color: #ff7600;
      }
    }
    .hide-text {
      padding-left: 0;
      &:before {
        display: none;
      }
    }
  }

  /* opened */
  &.active &__heading {
    color: #000;
  }


}

/* COLOR in catalog filter // inside hidden check group */
/*.color {
  width: 25px;
  height: 25px;
  margin: 1px;

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-left: -4px;
  }

  &__item {
    display: block;
    width: 17px;
    height: 17px;
    margin: 4px;
    position: relative;
    box-shadow: 0 0 0 1px rgba(0,0,0,.05);
    transition: box-shadow .15s;
  }

  &.checkbox {
    cursor: pointer;

    .checkbox__input:checked ~ .color__item {
      width: 21px;
      height: 21px;
      margin: 2px;
      box-shadow: 0 0 0 2px #fff, 0 0 7px 1px rgba(0,0,0,.2);
    }

    &.disabled {
      cursor: default;
    }
  }
}*/


/* –––––––––––––––––––––––––––– */
/* –––––––––– TABLET –––––––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 1023px) {

  .catalog-filter {
    display: none;
    margin-top: 112px;

    /* show/hide filter btn */
    &__toggle {
      display: block;
      position: absolute;
      top: 1px;
      left: 13px;
      right: 13px;
      height: 63px;
      border: 0;
      background: #fff;
      border-top: 1px solid #f3f3f3;
      border-bottom: 1px solid #f3f3f3;
      line-height: 64px;
      text-transform: uppercase;
      text-align: center;
      letter-spacing: .1em;
      font-weight: 600;
      outline: none;
      cursor: pointer;
      z-index: 1;

      &--show {

      }
      &--hide {
        display: none;
        transition: color .35s .55s;
      }
    }
  }

  .filter--opened {
    .catalog-filter {
      display: block;
      &__toggle {
        &--show {
          display: none;
        }
        &--hide {
          display: block;
          color: #ff7600;
        }
      }
    }
  }

}

@media (max-width: 1023px) and (min-width: 768px) {

  .filter--opened {
    & > .col-md-9.col-sm-12 {
      width: 66.66666667%;

      & .col-sm-8.col-sm-offset-4 {
        width: 100%;
        margin-left: 0;
      }

      & > .catalog-grid .col-md-4 {
        width: 50%;
      }
    }
  }

}

/* –––––––––––––––––––––––––––– */
/* –––––––––– MOBILE –––––––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 767px) {

  .catalog-filter {
    &__toggle {
      top: 19px;
      right: auto;
      left: calc(100% + 13px);
      width: calc(100% - 26px);
      background: transparent;
      border: 1px solid #474747;
      height: 37px;
      line-height: 37px;
    }

    &__btn {
      padding: 25px 20px 0;
    }
  }

  .filter--opened {
    .catalog-filter {
      position: absolute;
      top: 77px;
      left: -7px;
      right: calc(-100% - 7px);
      margin-top: 0;
      background-color: #fdfbf9;
      box-shadow: inset 0 1px 0 0 #f3f3f3, 0 10px 15px -5px rgba(0,0,0,.05);
      z-index: 3;

      &__heading {
        padding: 4px 20px 3px
      }
      &__inner {
        padding: 11px 20px 25px;
      }
      &--clear {
        padding: 7px 20px 23px;
      }
    }
  }

}
