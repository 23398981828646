.subscribe-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  &__wrapper {
    display: flex;
    flex-wrap: nowrap;
  }
  &__label {
    margin-right: -1px;
    display: flex;
    align-items: center;
  }
  &__text {
    line-height: 43px;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: .1em;
    padding-top: 1px;
  }
  &__input {
    width: 330px;
    margin-left: 27px;
  }
  &__submit {
    height: 45px;
    line-height: 45px;
    width: 45px;
    min-width: 45px;
    border-color: #ececec;
    background: #fff;

    &-icon {
      width: 7px;
      height: 12px;
      fill: #9a9a9a;
      margin-top: -2px;
      margin-left: 2px;
    }
  }
  &__input:focus ~ &__submit {
    border-color: #cdcdcd;
  }
  &__message {
    margin-left: 20px;
    text-transform: none;
  }


  /*** recovery password ***/
  &--recovery {
    flex-direction: column;
    text-align: center;
    margin-top: -11px;
    margin-bottom: 40px;

    .subscribe-form {
      &__label {
        margin-top: 24px;
      }
      &__input {
        margin-left: -3px;
      }
      &__submit {
        margin-left: -1px;
      }
      &__message {
        width: 100%;
        text-align: left;
        margin-left: 20.5%;
      }
    }
  }
}


/* –––––––––––––––––––––––––––– */
/* ––––––– SMALL DESKTOP –––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 1279px) {

  .subscribe-form {
    /*** recovery password ***/
    &--recovery {
      .subscribe-form {
        &__message {
          margin-left: 13.5%;
        }
      }
    }
  }

}

/* –––––––––––––––––––––––––––– */
/* –––––––––– TABLET –––––––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 1023px) {

  .subscribe-form {
    /*** recovery password ***/
    &--recovery {
      margin-top: -4px;
      .subscribe-form {
        &__message {
          margin-left: 0;
        }
      }
    }
  }

}


/* –––––––––––––––––––––––––––– */
/* –––––––––– MOBILE –––––––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 767px) {

  .subscribe-form {
    &__label {
      flex-direction: column;
      max-width: 100%;
      position: relative;
    }
    &__input {
      width: 235px;
      margin-left: 0;
    }
    &__message {
      margin-left: 0;
    }

    /* recovery pass */
    &--recovery {
      .recovery-form__text {
        line-height: 15px;
      }
      .subscribe-form {
        &__label {
          width: 100%;
          flex-direction: row;
        }
        &__input {
          margin-left: 0;
          width: 100%;
        }
        &__message {
          margin-left: 2px;
        }
      }
    }
  }

  .popup--footer-subscribe {
    &__input {
      width: auto;
      margin-left: 0;
    }
    &__label {
      margin-left: 0;
    }
  }

}
