.page-header {

  /* logo-links */
  &__logo-links {
    height: 84px;

    & .grid-row {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  &__logo-area {
    height: 84px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  /***** icons *****/
  &__items-wrapper {
    display: flex;
    padding-top: 6px;
    &--left {
      justify-content: flex-start;
    }
    &--right {
      justify-content: flex-end;
    }
  }

  &__item {
    position: relative;

    & .link {
      display: flex;
    }
    &-icon {
      fill: #474747;
      transition: fill .45s;
    }
    &:hover &-icon {
      fill: #ff7600;
    }
    &-text {
      padding-left: 8px;
      white-space: nowrap;
    }
    &:hover &-text {
      color:  #ff7600;
    }
    &-counter {
      display: none;
      padding-left: 8px;
      & span:first-child {
        margin-right: 4px;
      }
    }

    &.full {
      .page-header__item {
        &-counter {
          display: flex;
        }
      }
    }
  }


  &__phone {
    margin-right: 37px;
    &-icon {
      width: 10px;
      height: 16px;
    }
  }
  &__hours {
    position: relative;
    &-icon {
      width: 16px;
      height: 16px;
      margin-top: 1px;
    }

    &.is-closed {
      .opening-hours__status-opened {
        display: none;
      }
      .opening-hours__status-closed {
        display: flex;
      }
    }
  }
  &__compare {
    &-icon {
      width: 18px;
      height: 20px;
      margin-top: -2px;
    }
  }
  &__search {
    margin-left: 27px;
    cursor: pointer;

    &-icon {
      width: 19px;
      height: 19px;
      margin-top: -1px;
    }
  }
  &__acc {
    margin-left: 27px;
    &-icon {
      width: 18px;
      height: 18px;
      margin-top: -1px;
    }
  }
  &__cart {
    margin-left: 23px;
    &-icon {
      width: 22px;
      height: 17px;
    }
  }

  /*** hours mini-popup ***/
  &__hours-hover {
    position: absolute;
    background: #fff;
    top: -22px;
    left: -26px;
    z-index: 2;
    max-height: 0;
    height: auto;
    transition: max-height .65s ease-out .1s;
    overflow: hidden;

    &-inner {
      width: 220px;
      /*height: 215px;*/
      height: auto;
      padding: 54px 25px 20px;
      border: 1px solid #f3f3f3;
    }
  }

  &__hours-link {
    position: relative;
    z-index: 3;
  }
  &__hours:hover &__hours-hover {
    max-height: 500px;
  }


  /*** search ***/
  &__search {

    &-form {
      display: none;
      position: absolute;
      right: 13px;
      top: -10px;
      height: 45px;
      z-index: 1;
    }
    &-input {
      width: 345px;
      height: 45px;
      line-height: 44px;
      outline: none;
      border: 1px solid #f3f3f3;
      background-color: #fff;
      border-radius: 2px;
      padding: 2px 45px 0px 18px;
    }
    &-submit {
      background-color: #fff;
      position: absolute;
      top: 1px;
      right: 1px;
      outline: none;
      border: 0;
      height: 43px;
      width: 43px;
      z-index: 2;
      &-icon {
        width: 17px;
        height: 17px;
        fill: #474747;
        margin-top: 4px;
        margin-left: 4px;
      }
    }
  }
}/* end page-header */

.opening-hours {
  &__text {
    font-size: 12px;
    line-height: 25px;
    color: #545454;

    &--bold {
      font-weight: 700;
      margin-bottom: 7px;
    }
  }

  &__status {
    padding-top: 10px;

    &-opened {
      display: flex;
    }
    &-closed {
      display: none;
    }
    &-text {
      font-size: 12px;
      white-space: nowrap;
      padding-top: 1px;
    }
    &-opened &-icon {
      width: 16px;
      height: 16px;
      margin-top: 2px;
      margin-left: 1px;
      margin-right: 8px;
      fill: #61b376;
    }
    &-opened &-text {
      color: #63a474;
    }
    &-closed &-icon {
      fill: #ff4444;
      width: 16px;
      height: 16px;
      margin-top: 2px;
      margin-left: 1px;
      margin-right: 8px;
    }
    &-closed &-text {
      color: #ff3a4d;
    }
  }
  &__status + &__text {
    margin-top: 10px;
  }
}

/***** search *****/
.desktop-search {
  position: relative;
  margin-top: 5px;

  .form-input {
    padding-right: 40px;
  }
  &__submit {
    height: 35px;
    position: absolute;
    right: 0;
    top:0;
    margin: auto 0;
    background:transparent;
    border: none;
    padding: 0;
    outline: none;
  }
}

/***** main nav menu *****/
.navigation {
  display: flex;
  justify-content: center;
  position: relative;

  &__desktop-wrapper {
    display: flex;
    justify-content: center;
    position: static;
  }
  &--primary {
    border-top: 1px solid #f3f3f3;
    border-bottom: 1px solid #f3f3f3;
  }
  &__item {
    &--current {
      color: #000;

      &:hover {
        opacity: .75;
        color: #000;
      }
    }

    &--submenu {
      cursor: pointer;

      &-big {
        position: static;
      }
      /*&-small {
        position: relative;
      }*/
      &:hover {
        background-color: #fff;
        /*box-shadow: inset 0 1px 0 0 #e6e9ed;*/
        /*border-left: 1px solid #f3f3f3;
        border-right: 1px solid #f3f3f3;
        margin-left: -1px;
        margin-right: -1px;
        margin-bottom: -1px;*/
        /*border-bottom: 1px solid #fff;*/

        &:after {
          display: block;
          position: absolute;
          background-color: #fff;
          content: '';
          left: 0;
          right: 0;
          bottom: -1px;
          height: 1px;
          z-index: 6;
        }
      }
    }
  }
  &__submenu-column {
    padding-right: 30px;
    width: 200px;
    background: #fff;
  }
  &__submenu {
    display: none;
    position: absolute;
    /*background-color: #fff;*/
    z-index: 7;
    top: 61px;
    left: 0;
    right: 0;
    width: auto;
    padding: 42px 44px 34px 44px;
    cursor: default;
    /*border: 1px solid #f3f3f3;*/
    overflow: hidden;

    /*** first level menu ***/
    &-first {

      &-heading {
        display: inline-block;
        font-size: 12px;
        line-height: 14px;
        color: #000;
        padding: 5px 35px 11px 0;
        width: 270px;
      }

      &:hover &-heading,
      &.active &-heading {
        color: #ff7600;
      }
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 316px;
      background-color: #fdfbf9;
      border: 1px solid #f3f3f3;
      z-index: -1;
    }


    /*** second level menu ***/
    &-second {
      display: none;
      background-color: #fff;
      position: absolute;
      left: 316px;
      right: 0;
      top: 0;
      bottom: 0;
      padding: 46px 7px 46px 44px;
      border: 1px solid #f3f3f3;
      border-left: none;
      &-inner {
        display: flex;
        flex-wrap: wrap;
        overflow: hidden;
        max-height: 100%;
        max-width: 100%;
      }
      &-column {
        width: 25%;
        padding-right: 40px;
        margin-bottom: 31px;
      }
      &-heading.link {
        display: block;
        font-size: 12px;
        line-height: 16px;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: .1em;
        padding-right: 10px;
        margin-bottom: 10px;
      }
      &-list {
        padding-left: 10px;
        margin-bottom: 3px;
      }
      &-link {
        display: block;
        font-size: 12px;
        line-height: 14px;
        padding: 5px 0 6px;
      }
    }

    &-first:hover &-second,
    &-first.active &-second {
      /*display: block;*/
    }
    &-first:hover &-second {
      z-index: 1;
    }
  }

  &__item-name {
    height: 60px;
    line-height: 62px;
    margin: 0 30px;
    font-size: 13px;
    color: #000;
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;
    display: block;
  }
  &__item-name.link {
    font-weight: 700;
    letter-spacing: .1em;
    box-shadow: none;
    &:hover {
      box-shadow: none;
    }
  }
}

.navigation__item--submenu:hover .navigation__submenu,
.navigation__submenu:hover {
  display: block;
}
.navigation__item--submenu:hover .navigation__item-name {
  color: #ff7600;
}
/*.navigation__item--submenu .navigation__submenu--small {
  flex-direction: column;
}
.navigation__submenu--small .navigation__submenu-link {
  white-space: nowrap;
  text-decoration: none;
}*/

/***** FIXED header ****/
.fixed_head {
  .page-header {
    &__items-wrapper {
      display: none;
    }
  }
}
@media(min-width: 1024px) {

  .fixed_head .page-header {
    &__nav-area {
      .navigation {
        &__item-name {
           margin: 0 20px;
           font-size: 12px;
        }
      }
    }
  }
}
@media(max-width: 1024px) {
  .fixed_head {
    .page-header {
      &__nav-area {
        .navigation {
          &__item-name {
            margin: 0 10px;
            font-size: 11px;
          }
        }
      }
    }
  }
}
@media(max-width: 850px) {
  .fixed_head {
    .page-header {
      &__nav-area {
        .navigation {
        &__item-name {
           margin: 0 8px;
           font-size: 10px;
         }
        }
      }
    }
  }
}
@media(max-width: 768px) {
  .fixed_head {
    .page-header {
      &__nav-area {
        .navigation {
          &__item-name {
           margin: 0 20px;
           font-size: 12px;
          }
        }
      }
    }
  }
}

/* mobile queries */
@media(min-width: 768px) {
  .fixed_head {
    .page-header {
      height: 66px;
      position: fixed;
      right: 0;
      left: 0;
      top: 0;
      background: #fff;
      box-shadow: 0 0 29px 1px rgba(0,0,0,.25);
      z-index: 11;

      & .grid-container {
        width: auto;
      }

      &__nav-area {
        position: absolute;
        top: 0;
        left: 155px;
        right: 155px;


        .navigation {
          justify-content: flex-start;

          &__desktop-wrapper {
            width: 100%;
            justify-content: center;
          }
          &--primary {
            border-top: 0;
            border-bottom: 0;
          }
          &__item-name {
            height: 66px;
            line-height: 68px;
            white-space: nowrap;
          }
          &__submenu {
            top: 66px;
          }
        }
      }
      &__logo-links {
        height: 100%;
      }
      &__logo-area {
        height: 100%;
        position: absolute;
        top: 33px;
        left: -45%;

        .icon-logo {
          width: 150px;
          height: 45px;
        }
      }
      .menu-content__links {
        position: absolute;
        right: 13px;
        top: 14px;
        z-index: 1;
      }
      &__search,
      &__phone {
        display: none;
      }
    }
  }
}


/* –––––––––––––––––––––––––––– */
/* –––––––––– TABLET –––––––––– */
/* –––––––––––––––––––––––––––– */
@media(max-width: 1023px) {

  .page-header {
    &__mobile {
      &-area {
        height: 72px;
        background-color: #fff;
        box-shadow: 0 0 10px 0 rgba(0,0,0,.05),inset 0 -1px 0 0 #f3f3f3;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        z-index: 10;
        transform: translate3d(0,0,0);
        min-width: 360px;
      }
      &-logo {
        display: block;
        position: absolute;
        top: 0;
        left: 72px;
        right: 72px;
        height: 100%;

        .icon-logo--mobile {
          position: absolute;
          left: 50%;
          top: 5px;
          transform: translateX(-50%);
        }
      }
    }
    /*.fixed_head &__items-wrapper {
      &--right {
        display: flex;
        align-items: center;
      }
    }*/

    &__items-wrapper--right {
      display: flex;
      align-items: center;
      height: 72px;
      padding-top: 0;
      position: absolute;
      right: 0;
      top: 0;
      padding-right: 33px;
    }
    &__item {
      position: relative;
    }
    &__compare {
      &-icon {
        margin-top: 2px;
      }
    }
    &__acc {
      &-icon {
        margin-top: 2px;
      }
    }
    &__cart {
      & .link {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &-icon {
        margin-top: 2px;
      }
    }
    &__item-counter {
      position: absolute;
      top: -7px;
      left: 8px;
      right: -30px;
      font-size: 10px;
      justify-content: center;
      padding-left: 0;
      & span {
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 0;
      }
    }

    /***** side menu *****/
    &__nav-area {
      padding-bottom: 66px;
      display: block;
      position: fixed;
      top: 66px;
      height: 100%;
      left: -100%;
      right: 0;
      width: 100%;
      z-index: 9;
      overflow: auto;
      background: #fff;
      border-top: none;
      transition: left 0.3s ease-out;
      transform: translate3d(0,0,0);
      background-color: #fff;

      &--opened {
        display: block;
        right: 0;
        left: 0;
      }
    }

    &__phone--mobile {
      font-size: 20px;
      line-height: 30px;
      text-align: center;
      margin-right: 0;
      padding-top: 38px;
      padding-bottom: 24px;
    }

    &__logo-links {
      height: auto;
      position: fixed;
      right: 0;
      top: 0;
      z-index: 11;
    }

  }


  .burger-toggle {
    width: 72px;
    height: 72px;
    border: none;
    margin: 0;
    padding: 0;
    background: transparent;
    outline: none;
    background-image: svg('burger-menu');
    background-size: 29px 20px;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;

    &--opened {
      background-image: svg('cross-menu');
      background-size: 20px 20px;
    }
  }


  .mobile-search {
    position: relative;
    padding: 0 20px;
    margin-left: -13px;
    margin-right: -13px;

    /*&:after {
      content: '';
      display: block;
      height: 1px;
      margin-right: -30px;
      margin-left: -30px;
      background: #eee;
    }
    &:after {
      margin-top: 20px;
    }*/

    &__icon {
      width: 17px;
      height: 17px;
      margin-top: 2px;
      margin-left: 4px;
    }
    &__submit {
      height: 45px;
      width: 45px;
      position: absolute;
      right: 20px;
      top: 0;
      margin: auto 0;
      background:transparent;
      border: none;
      padding: 0;
      outline: none;
    }
  }

  .navigation {
    display: block;
    position: static;
    top: 90px;
    left: 0;
    right: 0;
    z-index: 1000;
    background: #fff;
    margin-left: -30px;
    margin-right: -30px;
    padding-bottom: 75px;

    &__desktop-wrapper {
       display: block;
       position: static;
    }
    &--primary {
      border-top: 0;
      border-bottom: 0;
      padding-top: 32px;
    }
    &--secondary {
      margin-top: 20px;
      .navigation__item {
        border: none;
        line-height: 40px;
        &--submenu:before{
          top: 19px;
        }
        &--submenu:after{
          top: 14px;
        }
      }
    }
    &__item {
      position: relative;
      display: block;
      padding: 0 30px 0 30px;
      font-size: 13px;
      text-transform: none;
      line-height: 55px;

      &--submenu {
        border-left: none!important;
        border-right: none!important;
        display: flex;
        flex-flow: row wrap;
        &:hover {
          border-bottom: none;
          border-top: none;
          margin-bottom: 0;
          box-shadow: none;
          margin-left: 0;
          margin-right: 0;
          &:after {
            display: none;
            background: #eee;
          }
        }
      }
      /*&:after {
        z-index: 10;
        content: '';
        display: block;
        height: 1px;
        width: 100%;
        position: absolute;
        left:0;
        bottom: 0 !important;
        background: #eee;
      }*/
    }
    &__item-name {
      padding: 0;
      margin: 0 7px;
      &.link {
        letter-spacing: .12em;
      }
    }
    &__submenu {
      position: relative;
      width: 100%;
      top: auto;
      padding: 0;
      border-bottom: none;
      border: none;
      padding-bottom: 12px;
      padding-left: 20px;
      padding-right: 20px;
      margin: 0 7px;

      &:before {
        display: none;
      }

      &-column {
        width: auto;
        padding-right: 0;
      }

      &-toggle,
      &-sub-toggle,
      &-sub-sub-toggle {
        flex: 1;
        position: relative;
        cursor: pointer;
      }
      &-toggle {
        height: 60px;
      }
      &-sub-toggle,
      &-sub-sub-toggle {
        height: 45px;
      }

      &-sub-toggle .icon-plus-minus,
      &-sub-sub-toggle .icon-plus-minus {
        width: 10px;
        height: 10px;
        position: absolute;
        top: 50%;
        right: 7px;
        transform: translateY(-6px);
        background-image: svg('plus') !important;
      }

      &-first.hey--im-opened &-sub-toggle .icon-plus-minus,
      &-second-column.hey--im-opened &-sub-sub-toggle .icon-plus-minus {
        background-image: svg('minus') !important;
      }

      &-heading{
        font-weight: normal;
        font-size: 16px;
        text-transform: none;
        margin-bottom: 10px;
        text-transform: uppercase;
      }

      /*** first level menu ***/
      &-first {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        min-height: 45px;
        line-height: 45px;

        &-heading {
          display: block;
          font-size: 13px;
          line-height: 16px;
          margin: 0;
          max-width: 85%;
          width: auto;
        }
      }


      /*** second level menu ***/
      &-second {
        width: 100%;
        min-width: 100%;
        background-color: transparent;
        position: relative;
        left: auto;
        right: auto;
        top: auto;
        bottom: auto;
        padding: 0 20px;
        border: none;
        &-inner {
          display: block;
        }

        &-column {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          width: 100%;
          padding: 0;
          margin: 0;
          min-height: 45px;
          line-height: 45px;
        }

        &-heading.link {
          display: block;
          font-size: 12px;
          line-height: 14px;
          font-weight: 400;
          text-transform: none;
          letter-spacing: 0;
          padding: 10px 0;
          margin-bottom: 0;
        }

        &-list {
          display: none;
          padding: 5px 20px;
          width: 100%;
        }
        &-link {
          padding: 10px 0;
        }
      }

      &-first:hover &-second,
      &-first.active &-second {
        display: none;
      }
      &-first.active &-first-heading {
        color: #000;
      }
      &-first.hey--im-opened &-second {
        display: block;
      }
      &-second-column.hey--im-opened &-second-list {
        display: block;
      }
      &-first.hey--im-opened &-first-heading,
      &-second-column.hey--im-opened &-second-heading {
        color: #ff7600;
      }
    }/*end submenu*/

    &__icon-wrapper {
      display: inline-block;
      width: 22px;
      margin-right: 24px;
      padding-bottom: 4px;
      svg {
        vertical-align: text-top;
      }
    }
  }


  .navigation__item--submenu:hover {
    & .navigation__submenu {
      display: none;
    }
  }
  :root .navigation__item--submenu--opened {
    .navigation__item-name {
      font-weight: 700;
    }
    .navigation__submenu {
      display: block;
    }
  }


  .navigation__item--submenu:hover + .navigation__submenu,
  .navigation__submenu:hover{
      display: none;
  }

  .navigation--secondary {
    .navigation__item:after {
      display: none;
    }
  }



} /* end tablet queries */

@media (max-width: 767px) {

  .page-header {
    &__cart {
      position: absolute;
      right: 0;
      top: 0;
      height: 72px;
      width: 72px;

      &-icon {
        width: 29px;
        height: 22px;
        margin-top: -2px;
        margin-left: -10px;
      }
    }
    &__item-counter {
      top: 15px;
      left: 28px;
      right: -3px;
    }
  }

  .navigation {
    margin-left: -13px;
    margin-right: -13px;

    &__item {
      padding-left: 13px;
      padding-right: 13px;
    }

    &__submenu {
      margin: 0;
    }
  }

}/* end mobile queries */
