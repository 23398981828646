.sign-in-actions{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
  line-height: 20px;
  font-size: 13px;

  &--centered {
    justify-content: center;
    margin: 15px 0;
  }
}
