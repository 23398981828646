.search-form {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin-top: 6px;
  margin-bottom: 54px;

  &__input {
    flex: 1;
    height: calc(25px + 20px);
    line-height: 25px;
    padding: 12px 50px 12px 20px;
    background: transparent;
    outline: none;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__submit {
    width: 50px;
    position: absolute;
    right: 0;
    top:0;
    bottom: 0;
    padding: 6px;
    cursor: pointer;
    border: none;
    background: transparent;
    outline: none;
  }
  &__submit-icon {
    width: 17px;
    height: 17px;
    fill: #000;
    position: absolute;
    top: 15px;
    right: 16px;
  }
  /*&__mobile-submit {
    background-image: svg('search--mobile', '[fill]: #000');
    background-size: 20px 20px;
    background-position: center;
    background-repeat: no-repeat;
    background-color: transparent;
    border: none;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 45px;
    outline: none;
  }*/
  &__message {
    flex:100%;
  }
}


.search-message {
  text-align: center;
  margin-top: -12px;
  margin-bottom: 34px;

  &__query {
    font-weight: 700;
    color: #434a54;
    padding-right: 5px;
    padding-left: 5px;
  }
}

/* –––––––––––––––––––––––––––– */
/* –––––––––– TABLET –––––––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 1023px) {

  .search-message {
    margin-top: -14px;
    margin-bottom: 29px;
  }

}

/* –––––––––––––––––––––––––––– */
/* –––––––––– MOBILE –––––––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 767px) {
  .search-form {
    margin-top: 3px;

    /*&__submit-icon {
      display: none;
    }*/
  }

  .search-message {
    margin-top: -1px;
    margin-bottom: 37px;
    line-height: 16px;
  }
}
