.tooltip {
  position: absolute;
  z-index: -1;
  top: calc(100% + 11px);
  left: 50%;

  visibility: hidden;

  padding: 5px 10px 5px;

  cursor: pointer;
  transition: opacity .45s .05s ease-out;
  transform: translateX(-50%);

  opacity: 0;
  border: 1px solid #ececec;
  background: #fff;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, .05);
  &__content {
    font-size: 12px;
    line-height: 20px;

    text-align: center;

    color: #545454;
    &:before {
      position: absolute;
      top: -20px;
      left: 0;

      width: 100%;
      height: 20px;

      content: '';
    }
  }
  &__link {
    display: block;

    padding: 4px;

    white-space: nowrap;
  }

  &:before,
  &:after {
    position: absolute;
    left: 50%;

    display: block;

    content: '';
    transform: translateX(-50%);

    border: 7px solid transparent;
  }
  &:before {
    z-index: 2;
    top: -10px;

    border-bottom: 4px solid #fff;
  }
  &:after {
    z-index: 1;
    top: -11px;

    border-bottom: 4px solid #ececec;
  }
}

.page-header__item:hover {
  .tooltip {
    z-index: 5;

    visibility: visible;

    opacity: 1;
  }
}
.catalog-grid--list {
  .product__compare, .product__not-av, .product__order, .product__cart{
    &:hover{
      .tooltip {
        z-index: 5;

        visibility: visible;

        opacity: 1;
      }
    }
    .tooltip{
      top: calc(100% + 15px);
      white-space: nowrap;
      -webkit-backface-visibility: hidden;
    }
  }
}
