.catalog-grid {
  /*display: flex;
  flex-flow: row wrap;*/
  padding-top: 18px;

  &__item {
    margin-bottom: 26px;
  }
  &__load-more-wrapper {
    width: 100%;
  }
  &__load-more {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 38px;
    padding-bottom: 38px;
    margin-bottom: 14px;

    &-icon {
      width: 28px;
      height: 28px;
      fill: #ff7600;

      &--simple {
        width: 19px;
        height: 30px;
        margin-right: 2px;
        margin-left: 7px;
        margin-top: -1px;
        fill: #ff7600;
      }
    }

    &-text {
      text-transform: uppercase;
      letter-spacing: .1em;
      font-size: 12px;
      margin-left: 10px;
      transition: color .35s;
    }

    &:hover &-text {
      color: #ff7600;
    }
  }

  /***** LIST *****/
  &--list {
    & > .col-md-4.col-sm-4 {
      width: 100% !important;
    }
    .catalog-grid__item {
      margin-bottom: 10px;
    }

    & .product {
      display: flex;
      height: 162px;
      min-height: 162px;

      &__image-wrapper {
        width: 170px;
        height: 100%;
      }

      &__info {
        text-align: left;
        display: flex;
        align-items: center;
        width: 78%;

        .product__link {
          display: none;
        }
      }

      &__title {
        padding-right: 80px;
        width: 48%;
      }
      &__price {
        flex-direction: column;
        margin-top: -17px;

        & .price {
          &__current {
            display: block;
          }
          &__old {
            display: block;
            margin-left: 0;
          }
        }
      }

      &__hover {
        opacity: 1;
        visibility: visible;
        left: auto;
        width: 28%;
        flex-direction: row;
        background: transparent;
        button{
          display: none;
        }
      }
      &__compare {
        order: 1;
      }
      &__cart,
      &__not-av,
      &__order {
        order: 2;
      }
      &__cart,
      &__not-av,
      &__order,
      &__compare {
        margin-bottom: 0;
        margin-left: 25px;
        margin-right: 25px;
        transform: scale(.85);

        &-icon {
          margin-top: 0;
          margin-bottom: 0;
        }
        &-text {
          display: none;
        }
      }
    }
  }

  /*** no filter view ***/
  &--no-filter {
    & .col-md-4 {
     width: 25%;
    }
  }
}

.catalog-sort--view {
  display: flex;
  justify-content: flex-end;
  margin-right: -5px;
  margin-top: -20px;
  margin-bottom: 2px;
  margin-top: -13px;

  &-trigger {
    padding: 5px;
  }

  .trigger {

    &-grid {
      cursor: pointer;

      &__icon {
        width: 20px;
        height: 20px;
        fill: #a9a9a9;
        transition: fill .35s;
      }
      &:hover &__icon {
        fill: #000;
      }
      &.active &__icon {
        fill: #ff7600;
      }
    }

    &-list {
      cursor: pointer;

      &__icon {
        width: 20px;
        height: 20px;
        fill: #a9a9a9;
        transition: fill .35s;
      }
      &:hover &__icon {
        fill: #000;
      }
      &.active &__icon {
        fill: #ff7600;
      }
    }
  }
}

/* –––––––––––––––––––––––––––– */
/* ––––––– SMALL DESKTOP –––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 1279px) {

  .catalog-grid {
    /***** LIST *****/
    &--list {
      & .product {

        &__info {
          width: 65%;
        }

        &__title {
          padding-right: 30px;
          width: 55%;
          margin-left: -20px;
        }
      }
    }
  }

}


/* –––––––––––––––––––––––––––– */
/* –––––––––– TABLET –––––––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 1023px) {

  .catalog-grid {
    padding-top: 16px;
    &__load-more {
      margin-bottom: 5px;
    }

    &--list {
      & .product {
        &__hover {
          display: flex;
        }
      }
    }

    &--no-filter {
      & .col-md-4 {
        width: 33.33333333%;
      }
    }
  }

}

/* –––––––––––––––––––––––––––– */
/* –––––––––– MOBILE –––––––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width:767px) {

  .catalog-grid {
    flex-flow: column nowrap;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 0;

    &__load-more {
      padding-top: 17px;
      padding-bottom: 17px;
      margin-bottom: 0;
    }

    &--list {
      & .product {
        &__hover {
          display: none;
        }
      }
    }

    &--no-filter {
      & .col-md-4 {
        width: 100%;
      }
    }
  }

}
