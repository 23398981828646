.slider-img {
  height: 100%;

  & .slick-list,
  & .slick-track {
    height: 100%;
    min-height: 100%;
  }

  & .slick-dots {
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
    margin-right: -50%;
  }

  &__slide {
    position: relative;
    height: 100%;
    overflow: hidden;
  }

  &__image {
    display: block;
    max-width: none !important;
    width: auto;
    height: 100%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin-right: -50%;
  }
}

/* –––––––––––––––––––––––––––– */
/* –––––––––– MOBILE –––––––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width:767px) {

  .slider-img {
    margin-left: -20px;
    margin-right: -20px;
  }

}
