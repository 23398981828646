.slider-blog {
  margin-left: -13px;
  margin-right: -13px;

  & .slick-list {
    margin-left: -13px;
    margin-right: -13px;
  }

  & .slick-arrow {
    position: absolute;
    top: -70px;
  }
  & .slick-prev {
    right: 20px;
  }
  & .slick-next {
    right: -10px;
  }

  /*** main page ***/
  &--main-page {
    margin-top: -4px;
  }

  /*** blog page ***/
  &--blog-page {
    margin-top: -10px;
  }
}

/* –––––––––––––––––––––––––––– */
/* –––––– SMALL DESKTOP ––––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 1279px) {

  .slider-blog {

    /*** blog page ***/
    &--blog-page {
      margin-top: 0;
    }
  }

}



/* –––––––––––––––––––––––––––– */
/* –––––––––– TABLET –––––––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 1023px) {

  .slider-blog {
    margin-left: 0;
    margin-right: 0;
  }

}


/* –––––––––––––––––––––––––––– */
/* –––––––––– MOBILE –––––––––– */
/* –––––––––––––––––––––––––––– */
@media(max-width: 767px) {

  .slider-blog {
    padding-bottom: 90px;

    & .slick-arrow {
      height: 95px;
      width: 100px;
      bottom: 10px;
      top: auto;
      background-size: 14px 20px;
    }
    & .slick-prev {
      right: calc(50% + 13px);
      background-position: center right;
    }
    & .slick-next {
      left: calc(50% + 13px);
      background-position: center left;
    }

    /*** blog page ***/
    &--blog-page {
      margin-top: -20px;
      padding-bottom: 20px;
    }

    /*** main page ***/
    &--main-page {
      margin-top: -24px;
      margin-bottom: 24px;
    }

  }

}
