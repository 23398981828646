.cart-item {
  display: flex;
  align-items: center;
  position: relative;

  &--not-available &__image,
  &--not-available &__total-price {
    opacity: 0.4;
  }
  &--not-available,
  &--not-available &__title,
  &--not-available &__article {
    color: #a4a4a4;
    &:hover {
      color: #a4a4a4;
    }
  }
  &--not-available &__price-counter,
  &--not-available .howmanyitems {
    display: none;
  }

  &__image {
    display: block;
    transition: transform .75s;
    &:hover {
      transform: scale(1.3);
    }
  }
  &__product-info {
    margin-left: -10px;
  }
  &__title {
    display: block;
    font-size: 11px;
    text-transform: uppercase;
    line-height: 18px;
    margin-top: -2px;

    &.link {
      letter-spacing: .1em;
    }
  }
  &__article {
    color: #545454;
    font-size: 12px;
    margin-top: 7px;
  }
  /*&__size {
    color: #a8a8a8;
    font-size: 12px;
  }*/
  &__price {
    display: flex;
    align-items: center;
    font-size: 13px;
    color: #545454;
  }
  &__price-counter {
    display: flex;
    align-items: center;
    margin-left: -2px;

    & .price {
      font-size: 13px;
      color: #545454;
      line-height: 15px;
      display: flex;
      white-space: nowrap;
      flex-wrap: wrap;

      &__old {
        display: block;
        font-size: 13px;
      }
    }
  }
  &__total-price {
    font-weight: 700;
    color: #545454;
    padding-left: 8px;
    margin-left: -26px;
    white-space: nowrap;
  }
  &__delete {
    background: none;
    border: none;
    outline: none;
    margin-left: auto;
    padding: 10px;
    margin-top: -3px;
    fill: #474747;
    transition: fill .55s;
    cursor: pointer;

    &:hover,
    &:active {
      fill: #ff7600;
    }
  }

  &__cross {
    &--small {
      width: 7px;
      height: 7px;
      margin: 0 25px;
      fill: #b3b3b3;
    }
    &--big {
      width: 8px;
      height: 8px;
      margin: 0;
    }
  }

  &__add-to-cart {
    cursor: pointer;

    &-wrapper {
      display: flex;
      align-items: center;
    }

    &-icon {
      width: 22px;
      height: 17px;
      fill: #474747;
      transition: fill .45s;
    }

    &:hover &-icon {
      fill: #ff7600;
    }
  }

  &__off-product,
  &__low-product {
    display: none;
    margin: 10px 0 10px 5px;
    font-size: 12px;
    font-weight: 600;
  }
  &__low-product {
    position: absolute;
    /*color: #ff3a4d;*/
    color: #000;
  }

  &--not-available &__off-product {
    display: block;
  }
  &--low-available &__low-product {
    display: block;
  }

  /*** account: order ***/
  &--order {
    .cart-item {

      &__image {
        margin-left: -5px;
        margin-right: 20px;
      }
      &__product-info {
        margin-left: -32px;
      }
      &__price-counter {
        margin-left: 10px;
        margin-top: -3px;
        .price {
          flex-direction: column;

          &__old {
            margin-left: 0;
          }
        }
      }
      &__cross--small {
        margin: 0 14px;
      }
      &__total-price {
        padding-left: 10px;
        padding-top: 17px;
        margin-left: 0;
      }
      &__add-to-cart {
        margin-left: -25px;
        /*height: 100%;*/
        display: flex;
        align-items: center;
      }

      &__off-product,
      &__low-product {
        margin-top: 8px;
        margin-left: 0;
        margin-bottom: 0;
        position: relative;
        font-size: 11px;
      }

    }

    &.cart-item--not-available .cart-item__add-to-cart {
      display: none;
    }
    &.cart-item--not-available .cart-item__price-counter {
      display: flex;
    }
    &.cart-item--not-available .cart-item__image,
    &.cart-item--not-available .cart-item__total-price {
      opacity: 1;
    }
    &.cart-item--not-available .cart-item__title,
    &.cart-item--not-available .cart-item__article,
    &.cart-item--not-available .cart-item__amount {
      color: #000;
    }
    &.cart-item--not-available .cart-item__title {
      &:hover {
        color: #ff7600;
      }
    }
  }/* end acc:order */


  /*** account: favorite ***/
  &--favorite {
    .cart-item {

      &__image {
        margin-left: -10px;
        margin-right: 36px;
      }
      &__product-info {
        margin-left: -40px;
      }
      &__price-counter {
        margin-left: 10px;
        margin-top: -5px;

        .price {
          flex-direction: column;

          &__old {
            margin-left: 0;
          }
        }
      }
      &__total-price {
        margin-left: 0;
      }
      &__add-to-cart {
        margin: -5px 25px 0;
      }
      &__off-product,
      &__low-product {
        margin-left: 10px;
        margin-bottom: 0;
        line-height: 14px;
      }
    }

    &.cart-item--not-available .cart-item__add-to-cart {
      display: none;
    }
  }/* end acc:favorite */


  /*** checkout ***/
  &--checkout {
    align-items: flex-start;

    &.grid-row {
      margin-left: 0;
      margin-right: 0;
    }
    .cart-item {
      &__image {
        margin-top: 15px;
        margin-left: -10px;
        margin-right: -10px;
      }
      &__product-info {
        margin-left: -8px;
        margin-top: 7px;
      }
      &__price-counter {
        margin-left: 4px;
        margin-top: 6px;
        .price {
          flex-direction: column;
          &__old {
            margin-left: 0;
          }
        }
      }
      &__cross--small {
        margin: 0 16px;
      }
      &__total-price {
        padding-left: 5px;
        padding-top: 17px;
        margin-left: 0;
      }
    }
  }/* end checkout */

  /*** shopping cart ***/
  &__fav-icon {
    width: 16px;
    height: 16px;
    fill: #474747;
    transition: fill .45s;
    margin-left: 50px;
    margin-top: -3px;
    cursor: pointer;

    &:hover,
    &.toggled {
      fill: #ff7600;
    }
  }

}

/* –––––––––––––––––––––––––––– */
/* ––––––– SMALL DESKTOP –––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 1279px) {

  .cart-item {
    &__product-innfo {
      margin-left: 0;
    }
    &__price-counter {
      margin-left: 4px;
    }
    &__total-price {
      padding-left: 0;
      margin-left: -28px;
      white-space: nowrap;
    }
    &__delete {
      margin-right: 2px;
    }
    &__fav-icon {
      margin-left: 10px;
    }

    /*** account: order ***/
    &--order {
      .cart-item {

        &__image {
          margin-left: -15px;
          margin-right: 15px;
        }
        &__product-info {
          margin-left: -10px;
        }
        &__add-to-cart {
          margin-left: -20px;
        }
      }
    }

    /*** account: favorite ***/
    &--favorite {
      .cart-item {

        &__image {
          margin-left: -10px;
          margin-right: 20px;
        }

        &__product-info {
          margin-left: -10px;
        }
      }
    }

    /*** checkout ***/
    &--checkout {
      .cart-item {
        &__image {
          margin-right: 0;
        }
        &__product-info {
          margin-left: 3px;
          margin-top: 5px;
        }
        &__price-counter {
          margin-left: 0;
          margin-top: 3px;
        }
        &__total-price {
          padding-left: 0;
          padding-top: 4px;
        }
      }
    }
  }

}

/* –––––––––––––––––––––––––––– */
/* –––––––––– TABLET –––––––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 1023px) {

  .cart-item {
    display: block;

    &__image {
      margin-left: -10px;
      width: calc(100% - 10px);

      &-wrapper.col-sm-2 {
        width: 14.5%;
      }
    }
    &__product-info {
      margin-left: -15px;
    }
    &__price-counter {
      margin-left: 14px;
      margin-top: 1px;
    }
    &__low-product {
      bottom: -70px;
      left: 22px;
    }
    &__price-counter {
      .price {
        flex-direction: column;
        margin-top: 3px;
        &__old {
          margin-left: 0;
          margin-top: 2px;
        }
      }
    }
    &__total-price {
      padding-left: 14px;
      padding-top: 18px;
      margin-left: 0;
    }
    &__fav-icon {
      position: absolute;
      right: 60px;
      top: 30px;
    }
    &__delete {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
    &__off-product {
      margin-left: 13px;
      margin-top: 5px;
      margin-bottom: 0;
    }


    /*** account: order ***/
    &--order {
      .cart-item {
        &__low-product {
          bottom: auto;
          left: auto;
        }
      }
    }

    /*** account: favorite ***/
    &--favorite {
      display: flex;

      .cart-item {

        &__image {
          margin-left: 0;
        }
        &__product-info {
          margin-left: -15px;
        }
        &__price-counter {
          margin-left: 15px;
        }
        &__low-product {
          bottom: -35px;
          left: 18px;
        }
        &__off-product {
          margin-left: 15px;
        }
      }
    }

  }

}


/* –––––––––––––––––––––––––––– */
/* –––––––––– MOBILE –––––––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width:767px) {
  .cart-item {

    &__image-wrapper.col-xs-1 {
      width: 100px;
      position: absolute;
      left: 0;
      top: 40px;
      bottom: 20px;
      overflow: hidden;
    }
    &__image {
      display: block;
      height: 100%;
      margin-left: 0;
      width: 100%;

      & img {
        display: block;
      }
      &:hover {
        transform: scale(1);
      }
    }
    &__product-info {
      margin-left: 0;
    }
    &__price-counter {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 0;
      margin-left: -1px;
      margin-top: 29px;
      .price {
        margin-top: 12px;
      }
    }
    &__total-price {
      padding-left: 0;
      padding-top: 17px;
    }
    &__fav-icon {
      position: relative;
      right: auto;
      top: auto;
      margin-top: 12px;
      margin-left: 2px;
      margin-bottom: -12px;
    }
    &__delete {
      margin: 0;
      position: absolute;
      right: 0px;
      top: 30px;
    }
    &__low-product {
      position: relative;
      line-height: 14px;
      bottom: auto;
      left: auto;
      margin-bottom: 0;
      margin-left: 0;
    }
    &__off-product {
      margin-left: 0;
      margin-top: 18px;
      margin-bottom: 2px;
    }

    &--not-available &__total-price {
      display: none;
    }


    /*** account: order ***/
    &--order {
      .cart-item {
        &__product-info {
          margin-left: 0;
        }
        &__price-counter {
          flex-direction: row;
          margin-left: 0;
          margin-top: 17px;
          align-items: center;

          .price {
            margin-top: 2px;
          }
        }
        &__total-price {
          padding-left: 0;
        }
        &__add-to-cart {
          margin-left: 4px;
          margin-top: 30px;
        }
      }
    }

    /*** account: favorite ***/
    &--favorite {
      display: block;

      .cart-item {

        &__image {
          margin-left: -13px;
        }
        &__product-info {
          margin-left: -10px;
        }
        &__price-counter {
          margin-left: -10px;
          margin-top: 6px;
        }
        &__low-product {
          bottom: auto;
          left: auto;
        }
        &__off-product,
        &__low-product {
          margin-left: -10px;
        }
        &__add-to-cart {
          margin: 30px 0 0 -5px;
        }
      }
    }
  }
}
