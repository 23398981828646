/***** CART *****/
.cart-toggle {

  &.toggled {

  }
}


/***** FAVORITE *****/
.favorite-toggle {
  display: flex;

  &__icon {
    width: 16px;
    height: 16px;
    fill: #474747;
    transition: fill .35s;
  }
  &__text {
    padding-left: 10px;
    transition: color .35s;
  }
  &.toggled {
    .favorite-toggle {
      &__icon {
        fill: #ff7600;
      }
      &__text {
        color: #ff7600;
      }
    }
  }

  &.disabled {
    opacity: .45;
    cursor: default;

    .favorite-toggle {
      &__icon {
        fill: #474747;
      }
      &__text {
        color: #000;
      }
    }
  }

}


/***** COMPARE *****/
.compare-toggle {
  display: flex;

  &__icon {
    width: 17px;
    height: 19px;
    fill: #474747;
    transition: fill .35s;
  }
  &__text {
    padding-left: 10px;
    transition: color .35s;
  }
  &.toggled {
    .compare-toggle {
      &__icon {
        fill: #ff7600;
      }
      &__text {
        color: #ff7600;
      }
    }
  }

  &.disabled {
    opacity: .45;
    cursor: default;

    .compare-toggle {
      &__icon {
        fill: #474747;
      }
      &__text {
        color: #000;
      }
    }
  }

}

@media (min-width: 1023px) {

  .favorite-toggle,
  .compare-toggle {

    &:hover &__icon {
      fill: #ff7600;
    }

    &:hover &__text {
      color: #ff7600;
    }
  }

}