.news-banner {
  min-height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 35px;
  padding-bottom: 30px;

  &__text {
    font-size: 16px;
    line-height: 23px;
    padding: 1px 0 5px;
    color: #000;
    font-weight: 600;
  }

  &__btn {
    margin-left: 28px;
    & .btn {
      min-width: 130px;
      margin-bottom: 0;
    }
  }
}

@media (max-width: 767px) {

  .news-banner {
    flex-direction: column;
    text-align: center;

    &__text {
      max-width: 300px;
      margin: 0;
      margin-top: 10px;
    }

    &__btn {
      margin-left: 0;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }

}
