.account-favorite {
  margin-top: 40px;

  & .cart,
  & .account-menu {
    margin-top: 18px;
  }

  & .cart-row {
    padding-top: 44px;
    padding-bottom: 42px;
  }

}


/* –––––––––––––––––––––––––––– */
/* ––––––– SMALL DESKTOP –––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 1279px) {

  .account-favorite {
    margin-bottom: 20px;

    & .cart,
    & .account-menu {
      margin-top: 7px;
    }

  }

}

/* –––––––––––––––––––––––––––– */
/* –––––––––– TABLET –––––––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 1023px) {

  .account-favorite {
    margin-top: 5px;

    & .cart {
      margin-top: 60px;
      margin-bottom: 20px;
    }
  }

}


/* –––––––––––––––––––––––––––– */
/* –––––––––– MOBILE –––––––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 767px) {

  .account-favorite {
    margin-top: 0;

    & .account-menu {
      margin-top: 0;
    }
    & .cart {
      margin-bottom: 30px;
    }
  }

}
