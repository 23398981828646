.icon-plus-minus {
  width: 11px;
  height: 11px;
  background-repeat: no-repeat;
  background-image: svg('plus');
  background-size: 100%;
  
  &--navigation {
    width: 13px;
    height: 13px;
    position: absolute;
    top:50%;
    right: 7px;
    transform: translateY(-6px);
  }
}

.navigation__item--submenu--opened {
  & .icon-plus-minus {
    background-image: svg('minus');
  }
}
