.product {
  min-height: 405px;
  border: 1px solid #f3f3f3;
  position: relative;
  overflow: hidden;

  &__link {
    display: block;
    /*height: 100%;*/
  }

  /*&--small &__link {
    height: 260px;
  }*/
  &__image-wrapper {
    overflow: hidden;
    height: 280px;
  }
  &__image {
    display: block;
    position: relative;
    width: auto;
    max-width: 100%;
    max-height: 100%;
    margin: 0 auto;
    padding: 35px;
  }
  /*&--small &__image {
    padding-top: 20px;
  }*/
  &__info {
    padding: 0 30px;
    text-align: center;
  }
  &__title {
    display: block;
    max-height: 50px;
    margin-top: -2px;
    font-size: 11px;
    line-height: 18px;
    letter-spacing: .1em;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: uppercase;
  }

  /*&--small &__name {
    text-transform: uppercase;
  }*/

  /*&__description {
    color: #4d4d4d;
    font-size: 14px;
    position: relative;
    line-height: 1.2;
  }*/

  &__price {
    font-size: 15px;
    padding-top: 12px;
    .price {
      &__current {
        font-weight: 700;
      }
      &__old {
        color: #a4a4a4;
        margin-right: 12px;
      }
    }
  }
  &__saving {
    font-size: 11px;
    line-height: 15px;
    color: #545454;
    padding-top: 6px;
    white-space: nowrap;
  }

  /*** badges ***/
  &__badges {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    flex-flow: column;
    align-items: flex-end;
    z-index: 1;
  }
  &__badge {
    height: 20px;
    position: relative;
    margin-top: 3px;
    font-size: 11px;
    line-height: 20px;
    text-transform: uppercase;
    padding: 0 8px;
    text-align: right;
    background-color: #999;
    transition: background-color .45s;
    letter-spacing: .1em;
    color: #fff;

    &--free-shipping {
      background-color: #50dd85;
    }
    &--sale {
      background-color: #ff7600;
    }
    &--new {
      background-color: #32dbf9;
    }
    &--hit {
      background-color: #ff7184;
    }
    &--gift{
       background-color: #ff4444;
    }
  }

  /*** hover stuff: sizes, compare, add-to-something ****/
  &__hover {
    transition: opacity 0.5s, visibility 0s 0.5s;
    position: absolute;
    visibility: hidden;
    opacity: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(255,255,255,.9);
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    z-index: 2;
  }
  &:hover &__hover {
    visibility: visible;
    transition-delay: 0s;
    opacity: 1;
  }

  /*** sizes ***/
  &__size-label {
    width: 150px;
    text-align: center;
    color: #000;
    font-size: 14px;
    padding-bottom: 18px;
    text-transform: uppercase;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      width: 25px;
      height: 1px;
      left: 50%;
      margin-left: -13px;
      background: #000;
    }
  }
  &__sizes {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 10px;
    max-width: 80%;
  }
  &__size {
    margin: 7px;
    font-size: 13px;
    color: #000;
    opacity: .6;
    & ~ .product__size{
     margin-top: 7px;
    }
  }

  /*** add-to-cart ***/
  &__cart {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;

    &-icon {
      width: 37px;
      height: 28px;
      margin-bottom: 13px;
      margin-left: -10px;
      fill: #474747;
      transition: fill .35s;
    }
    &:hover &-icon,
    &.toggled &-icon {
      fill: #ff7600;
    }
    &-text {
      color: #000;
      transition: color .35s;
    }
    &:hover &-text,
    &.toggled &-text {
      color: #ff7600;
    }
  }
  &__order,
  &__not-av  {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    cursor: default;
    &-text {
      color: #000;
    }
  }
  &__order {
    &-icon {
      width: 37px;
      height: 37px;
      margin-top: -22px;
      margin-bottom: 12px;
      fill: #474747;
    }
  }
  &__not-av {
    &-icon {
      width: 33px;
      height: 31px;
      margin-top: -16px;
      margin-left: 3px;
      margin-bottom: 11px;
      fill: #474747;
    }
  }

  /*** add-to-compare ***/
  &__compare {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &-icon {
      width: 30px;
      height: 30px;
      margin-left: -2px;
      margin-bottom: 11px;
      fill: #474747;
      transition: fill .35s;
    }
    &:hover &-icon,
    &.toggled &-icon {
      fill: #ff7600;
    }
    &-text {
      color: #000;
      transition: color .35s;
    }
    &:hover &-text,
    &.toggled &-text {
      color: #ff7600;
    }
  }

  /*&--small &__info {
    padding: 15px 0;
  }*/

  &__favorite {
    display: none;
  }


  /*** compare page */
  &--compare {
    border: 0;
    overflow: hidden;
    min-height: 445px;
    height: 445px;
    margin-bottom: 44px;

    .product {
      &__link {
        height: 298px;
      }
      &__info {
        height: 112px;
      }
      &__image-wrapper {
        height: 290px;
        padding-top: 20px;
      }
    }

    & .cart-item__delete {
      position: absolute;
      right: -2px;
      top: 4px;
    }
  }/* end compare */

}

/* –––––––––––––––––––––––––––– */
/* ––––––– SMALL DESKTOP –––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 1279px) {

  .product {
    min-height: 365px;

    &__image-wrapper {
      height: 224px;
    }
    &__price {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-top: 8px;
      line-height: 21px;
    }


    /*** compare page */
    &--compare {
      min-height: 385px;
      height: 385px;
      .product {
        &__link {
          height: 238px;
        }
        &__image-wrapper {
          height: 235px;
        }
      }
    }
  }

}

/* –––––––––––––––––––––––––––– */
/* –––––––––– TABLET –––––––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 1023px) {

  .product {
    &__hover {
      display: none;
    }
    &--compare {
      & .cart-item__delete {
        transform: translateY(0);
      }
    }
  }

}



/* –––––––––––––––––––––––––––– */
/* –––––––––– MOBILE –––––––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 767px) {

  .product {
    min-height: 405px;

    &__image-wrapper {
      height: 280px;
    }
    &__price {

    }
  }

}
