.product-info {
  padding-bottom: 30px;

  &__row {
    padding: 0 0 15px;
    position: relative;

    &--sizes,
    &--colors {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &--tabs {
      margin-top: 15px;
    }
  }
  &__title {
    font-size: 24px;
    font-weight: normal;
    line-height: 1;
    margin-bottom: 10px;
    margin-top: 20px;
  }
  &__price {
    font-size: 24px;
    color: #000;
    font-weight: 700;

    .price__old {
      font-size: 24px;
      color: #a4a4a4;
      font-weight: 400;
      margin-left: 0;
      margin-right: 15px;
    }
  }
  &__article {
    font-size: 12px;
    margin-left: 11px;
    padding-left: 12px;
    position: relative;

    &:before {
      display: block;
      content: '';
      position: absolute;
      left: 0;
      height: 9px;
      width: 1px;
      top: 4px;
      background: #545454;
      transform: rotate(20deg);
    }
  }

  &__feedback {
    padding-top: 20px;

    & .forum__summary {
      margin-bottom: 15px;
      &-title {
        white-space: normal;
      }
    }
  }

  .product-brand {
    &__wrapper {
      display: flex;
      padding-bottom: 10px;
      margin-bottom: 13px;
    }
    &__text {
      font-size: 12px;
      margin-right: 4px;
    }
  }
  &__brand {
    font-size: 12px;
  }

  &__btns {
    margin-top: 26px;
    display: flex;
    align-items: center;

    & .btn {
      white-space: nowrap;
    }

    & .cart-toggle,
    & .btn--not-available {
      width: 48%;
    }
    & .favorite-toggle {
      margin-left: 24px;
      margin-top: -1px;
    }
    & .compare-toggle {
      margin-left: 26px;
      margin-top: -1px;
    }
  }
  &__gift{
    box-shadow: inset 0 -1px 0 0 #f3f3f3, inset 0 1px 0 0 #f3f3f3;
    padding: 20px 0 23px;
    margin-top: 23px;
    &-heading{
      line-height: 34px;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: .1em;
      margin-bottom: 5px;
    }
    &-right{
      display: flex;
      flex-wrap: wrap;
    }
    &-item{
      display: flex;
    }
    &-img{
      display: flex;
      align-items: center;
      justify-content: center;
      max-height: 85px;
      width: 85px;
      min-width: 85px;
      max-width: 85px;
      flex: 0 0 85px;
      position: relative;
      padding: 0 10px;
    }
    &-title{
      text-transform: uppercase;
      font-size: 11px;
      letter-spacing: .1em;
      margin-top: 17px;
      min-width: 65%;
      max-width: 65%;
      padding: 0 20px 0 15px;
    }
    &-article{
      display: inline-block;
      margin-top: 15px;
      padding-left: 15px;
      white-space: nowrap;
      color: #545454;
    }
  }
}

/* –––––––––––––––––––––––––––– */
/* ––––––– SMALL DESKTOP –––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 1279px) {

  .product-info {
    &__btns {

      & .cart-toggle,
      & .btn--not-available {
        width: 65%;
      }
    }
  }

}

/* –––––––––––––––––––––––––––– */
/* –––––––––– TABLET –––––––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 1023px) {

  .product-info {

    &__title {
      line-height: 28px;
      margin-bottom: 8px;
      margin-top: 20px;
    }

    &__row {
      &--tabs {
        .tab-panel {
          &--styled {
            justify-content: space-between;
            .tab-panel {
              &__tab {
                margin-right: 0;
              }
            }
          }
        }
      }
    }

    &__gift{
      &-title{
        max-width: none;
      }
    }
  }

}


/* –––––––––––––––––––––––––––– */
/* –––––––––– MOBILE –––––––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 767px) {
  .product-info {
    margin-bottom: 16px;
    padding-top: 10px;
    padding-bottom: 67px;
    position: relative;

    &:before {
      display: block;
      content: '';
      position: absolute;
      left: -20px;
      right: -20px;
      height: 1px;
      bottom: 0;
      background: #f3f3f3;
    }

    &__row {
      padding: 10px 0;

      &--tabs {
        .tab-panel {
          &--styled {
            justify-content: flex-start;
            .tab-panel {
              &__tab {
                margin-right: 35px;
              }
            }
          }
        }
      }
    }

    .product-brand {
      &__wrapper {
        margin-bottom: 12px;
      }
    }

    &__btns {
      margin-top: 21px;
      flex-wrap: wrap;
      justify-content: center;

      & .cart-toggle,
      & .btn--not-available {
        width: 100%;
      }

      & .favorite-toggle {
        margin-left: 0;
        margin-top: 18px;
      }

      & .compare-toggle {
        margin-top: 18px;
      }
    }
  }
}
