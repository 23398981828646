/*** type 3 : small ***/
.slider-news {
  width: 488px;
  display: block;
  text-decoration: none;
  transition: color .45s;


  &__slide {
    width: 488px;
    height: 474px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fafafa;
    text-align: center;
  }

  &__wrapper {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__title {
    margin-top: 30px;
    margin-bottom: 43px;
    font-size: 20px;
    line-height: 30px;
    text-transform: uppercase;
    letter-spacing: .1em;
    font-weight: 600;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      bottom: -20px;
      width: 40px;
      height: 2px;
      background: #000;
      left: 50%;
      margin-left: -20px;
      transition: opacity .45s;
    }
  }

  &:hover &__title {
    &:after {
      opacity: .65;
    }
  }

  &__description {
    width: 280px;
    margin: 0 auto;
    padding-left: 0;
    font-size: 16px;
  }

  & .icon-news {
    margin-top: -30px;
  }

  & .slick-dots {
    bottom: 36px;
    left: 50%;
    transform: translateX(-50%);
    margin-right: -50%;

    & li {
      width: 4px;
      height: 4px;
      margin-left: 10px;
      margin-right: 9px;
      box-shadow: 0 0 0 1px rgba(219,219,219,1);
      background: rgba(219,219,219,1);
    }
    & li.slick-active {
      box-shadow: 0 0 0 1px rgba(0,0,0,1);
      background: rgba(0,0,0,1);
    }
  }

}

@media (max-width: 767px) {
  .slider-news {
    width: 100%;
  }
}
