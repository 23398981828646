.link {
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  letter-spacing: inherit;
  transition: border-color .55s, color .45s, border .45s;
  text-decoration: none;

  &--no-color {
    color: inherit;
  }

  &--primary {
    color: #000;
    border-bottom: 1px solid #000;

    &:hover {
      color: #ff7600;
      text-decoration: none;
      border-color: #ff7600;
    }
  }

  &--secondary {
    color: #000;

    &:hover {
      color: #ff7600;
      text-decoration: none;
      border: 0;
    }
  }

  &--styled {
    color: #000;
    border-bottom: 1px solid transparent;

    &:hover {
      color: #ff7600;
      text-decoration: none;
      border-color: #ff7600;
    }
  }

  /*&--bold {
    font-weight: 700;
    letter-spacing: .05em
  }*/
  &--dotted {
    color: #000;
    border-bottom: 1px dotted #000;

    &:hover {
      color: #ff7600;
      border-color: #ff7600;
    }
  }
  /*&--centered {
    display: block;
    margin: 0 auto;
    text-align: center;
    width: 100%;
  }*/
  /*&--red {
    color: #ff3a4d;
  }*/

  &--gray {
    color: #a4a4a4;
    border-bottom: 1px solid #a4a4a4;

    &:hover {
      color: #a4a4a4;
      border-bottom: 1px solid transparent;
    }
  }
}
