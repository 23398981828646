.paginator {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin-bottom: 30px;
  margin-top: 45px;
  min-height: 67px;

  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: #f3f3f3;
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: #f3f3f3;
  }

  &__arrow {
    line-height: 65px;
    display: block;
    position: absolute;
    top: 1px;
    width: 178px;
    text-align: center;
    background-repeat: no-repeat;
    background-size: 18px 10px;
    color: #545454;
    text-transform: lowercase;

    /* vertical separator after arrow */
    /*&:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      height: 100%;
      width: 1px;
      background: #f3f3f3;
    }*/

    &--prev {
      left: 7px;
      background-image: svg('arrow-left', '[fill]: #9a9a9a');
      background-position: 0 center;

      /*&:before {
        right: 0;
      }*/

      &:hover {
        background-image: svg('arrow-left', '[fill]: #ff7600');
      }
    }

    &--next {
      right: 7px;
      background-image: svg('arrow-right', '[fill]: #9a9a9a');
      background-position: right 0 center;

      /*&:before {
        left: 0;
      }*/

      &:hover {
        background-image: svg('arrow-right', '[fill]: #ff7600');
      }
    }

    &--disabled {
      opacity: .45;
      cursor: default;

      &:hover {
        color : #545454;
      }
    }
    &--disabled&--prev:hover {
      background-image: svg('arrow-left', '[fill]: #9a9a9a');
    }
    &--disabled&--next:hover {
      background-image: svg('arrow-right', '[fill]: #9a9a9a');
    }
  }
}

@media (max-width: 767px) {
  .paginator {
    margin-top: 20px;
    margin-bottom: 84px;
    &:before{
      position: absolute;
    }
    &:after{
      position: absolute;
    }
  }
}
