.icon {
  transition: opacity .45s;
  /* header */

  &-logo {
    width: 200px;
    height: 84px;

    &--small {
      width: 87px;
      height: 40px;
    }

    &--footer {
      width: 112px;
      height: 60px;
    }
  }

  &-burger-menu {
    width: 31px;
    height: 22px;
  }

  &-search {
    width: 17px;
    height: 20px;
    fill: #000;
    margin-top: 3px;
    margin-right: 14px;

    &--mobile {
      width: 20px;
      height: 20px;
      fill: #89878c;
    }
  }

  &-account {
    width: 15px;
    height: 18px;
    fill: #000;

    &-full {
      width: 17px;
      height: 18px;
      fill: #000;
      margin-top: 2px;
    }
    &--mobile {
      width: 20px;
      height: 20px;
    }
  }


  /* common */
  &-arrow-left,
  &-arrow-right {
    width: 10px;
    height: 19px;
  }
  &-plus,
  &-minus {
    width: 11px;
    height: 11px;
  }
  &-plus--big {
    width: 11px;
    height: 11px;
    fill: #000;
    margin-right: 5px;
    margin-bottom: 2px;
  }
  /*&-badge {
    width: 4px;
    height: 15px;
    position: absolute;
    left:-2px;
    top: 0;
    &--free-shipping {
      fill: #3bcaf3;
    }
    &--sale {
      fill: #f03d56;
    }
    &--new {
      fill: #28d3d9;
    }
    &--hit {
      fill: #ffcb68;
    }
  }*/

  /* address select */
  &-pencil {
    width: 12px;
    height: 12px;
  }
  &-cross {
    &--big {
      width: 12px;
      height: 12px;
    }
  }

}

/* components page sprite rules */
.icon-layout-test {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  .icon-layout__icon {
    width: 30px;
    height: 30px;
    margin: 20px;
  }
}

/* –––––––––––––––––––––––––––– */
/* –––––––––– TABLET –––––––––– */
/* –––––––––––––––––––––––––––– */
@media(max-width: 1023px) {

  .icon-logo--mobile {
    width: 200px;
    height: 64px;
  }

}

/* –––––––––––––––––––––––––––– */
/* –––––––––– MOBILE –––––––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 767px) {

  .icon-logo--mobile {
    width: 173px;
    height: 60px;
  }

}
