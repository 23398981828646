.map-fancybox.fancybox-wrap,
.map-fancybox .fancybox-skin,
.map-fancybox .fancybox-outer,
.map-fancybox .fancybox-inner,
.map-fancybox .fancybox_popup{
  width: 100% !important;
  height: 100% !important;
}
.map-fancybox.fancybox-wrap{
  bottom: 0!important;
  position: fixed!important;
}

.map-fancybox .fancybox-close{
  background-color: #fff!important;
}

.fancybox-close {
  top: 0;
  right: 0;
  width: 56px;
  height: 56px;
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;

  &:after {
    display: block;
    content: '';
    background-image: svg('cross','[fill]: #000');
    background-size: 17px 17px;
    background-position: center;
    background-repeat: no-repeat;
    width: 17px;
    height: 17px;
  }

  &:hover:after {
    background-image: svg('cross', '[fill]: #6a6a6a');
  }
}

.fancybox-lock .fancybox-overlay {
  overflow: auto;
  overflow-y: auto;
}
