/*** sorting + page nav + pagintation ***/
.catalog-sorting {
  height: calc(23px + 40px);
  line-height: 25px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
  padding: 20px 10px;
  margin-top: 1px;
  margin-bottom: 32px;
  border-top: 1px solid #f3f3f3;
  border-bottom: 1px solid #f3f3f3;

  /*&--bottom {
    padding: 15px 20px;
    margin-top: 22px;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      height: 1px;
      left: 0;
      right: 0;
      background: #e8e8e8;
    }
  }*/


  /***** row filter *****/
  &__row-filter {
    display: flex;

    &-title {
      margin: 0;
      color: #545454;
      text-transform: none;
      letter-spacing: normal;
      font-weight: normal;
    }

    &-links {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-content: space-between;
      min-height: 25px;
      position: relative;
      margin-left: 6px;

      & .link {
        display: block;
        height: 22px;
        margin: 0 6px;
        position: relative;
        font-size: 12px;
        color: #000;

        &.current {
          color: #ff7600;
          font-weight: 600;
        }
      }

      .sorting {
        &--asc.link {
          margin: 0 10px 0 6px;

          &:after {
            display: block;
            content: "↑";
            position: absolute;
            top: -1px;
            right: -8px;
          }
        }

        &--desc.link {
          margin: 0 14px 0 6px;

          &:after {
            display: block;
            content: "↓";
            position: absolute;
            top: -1px;
            right: -8px;
          }
        }
      }
    }
  }/* end row filter */

  /***** select filter *****/
  &__select-filter {
    width: 100%;

    .chosen-container.chosen-container-single .chosen-single {
      height: 37px;
      line-height: 35px;
      margin-top: -1px;
    }

    .chosen-select {
      padding-right: 34px;
      width: 100%;
      height: 37px;
      padding-left: 10px;
    }
  }


  /***** central text *****/
  .catalog-sorting__items {
    &-amount {
      line-height: 25px;
      font-size: 12px;
      color: #434a54;
      text-align: center;
      overflow: hidden;
      -webkit-text-overflow: ellipsis;
      -moz-text-overflow: ellipsis;
      text-overflow: ellipsis;
      position: absolute;
      left: 300px;
      right: 300px;
    }
    &-text {

    }
  }/* end sorting central text */

  /***** sorting pagination ******/
  &__pagination {
    display: flex;
    margin-top: 3px;

    &-display-items {
      display: flex;
      position: relative;
      padding-left: 35px;
      vertical-align: top;
      align-items: center;

      & .item {
        display: block;
        float: left;
        padding: 0 6px;
        line-height: 25px;
        font-size: 12px;
        text-decoration: none;
        color: #545454;
        transition: color .35s;

        &:hover {
          color: #000;
        }

        &.current,
        &.active {
          font-weight: 700;
          color: #000;
        }
      }
    }
  }/* end pagination */
}


/***** pagination *****/
.pagination {
  display: flex;
  align-items: center;
  position: relative;

  & ul,
  & ul li {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}

.catalog-sorting__pagination-display-items {
  & + .pagination {
    padding-left: 23px;
    margin-left: 23px;

    &:before {
      position: absolute;
      top: -3px;
      bottom: -3px;
      left: 0;
      content: '';
      display: block;
      width: 1px;
      background: #f3f3f3;
    }
  }
}

.pagination_arrow {
  &_link {
    display: block;
    height: 100%;
    position: relative;
    transition: all 0.2s ease-out;
    width: 25px;
    height: 25px;

    &:after {
      display: block;
      content: "";
      height: 7px;
      width: 7px;
      position: absolute;
      left: 12px;
      top: 9px;
      border: 1px solid #9a9a9a;
      border-width: 1px 0 0 1px;
      transform: rotate(-45deg);
      transition: all 0.2s ease-out;
    }

    &.next:after {
      left: 12px;
      right: 2px;
      border-width: 0 1px 1px 0;
    }

    &:hover:after {
      border-color: #000;
    }
  }
}


ul li.pagination_all {
  margin-left: 20px;
}

.pagination_item .page {
  color: #000;
  font-size: 12px;
  font-weight: 300;
  text-align: center;
  min-width: 20px;
  transition: opacity .45s;

  &.current,
  &.active {
    font-weight: 700;
  }

  &:hover {
    opacity: .65
  }
}
.pagination_item .page,
.pagination_all .page {
  display: block;
  padding: 0 5px;
  text-decoration: none;
}

/* "All" / "Все" */
.pagination_all .page {
  color: #000;
  padding-right: 0;
  margin-left: 16px;
  transition: color .35s;

  &:hover {
    color: #ff7600;
  }
}


/* –––––––––––––––––––––––––––– */
/* –––––––––– TABLET –––––––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 1023px) {

  .catalog-sorting__pagination-display-items {
    & + .pagination {
      padding-left: 0;
      margin-left: 0;

      &:before {
        display: none;
      }
    }
  }
  .pagination {
    padding-left: 0;
    margin-left: 0;
  }  

}

/* –––––––––––––––––––––––––––– */
/* –––––––––– MOBILE –––––––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 767px) {

  .catalog-sorting {
    padding: 0 0 0 7px;
    margin: 0;
    border: 0;

    &__mobile-wrapper {
      padding: 20px 0;
      height: 77px;
      background-color: #fdfbf9;
      &.grid-row {
        margin-left: -20px;
        margin-right: -20px;
      }
    }
    &__pagination-display-items {
      & + .pagination {
        padding-left: 0;
        margin-left: 0;

        &:before {
          display: none;
        }
      }
    }
  }

}
