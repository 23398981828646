.cart {
  margin-top: 13px;

  &.grid-row,
  &-row__order-details.grid-row {
    margin-left: 0;
    margin-right: 0;
  }

  &-row {
    min-height: 146px;
    padding-top: 37px;
    padding-bottom: 37px;
    position: relative;

    &__borders {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      &:after{
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background: #f3f3f3;
      }
    }
    &:first-child &__borders:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background: #f3f3f3;
    }

    /* order details */
    &__order-details {
      padding-bottom: 35px;
    }

    /* create order row */
    &__checkout-btn {
      min-height: auto;
      padding-top: 30px;
      padding-bottom: 70px;
    }

    /* promo codes */
    &__checkout-btn + &__promo-codes {
      .promo-codes {
        margin-top: -135px;
      }
    }
  }/* end cart row */

}/* end cart */

/*** account : orders ***/
.account-order {
  .cart {
    &-row {
      align-items: stretch;
      padding-top: 35px;
      padding-bottom: 33px;
      padding-left: 0;
    }
    &-footer {
      padding-top: 35px;
      padding-bottom: 45px;

      .cart-row__borders {
        left: 13px;
        right: 13px;
      }
      .order-details {
        &__info {
          padding: 1px 0;

          td {
            padding: 7px 36px 8px 0;
            line-height: 16px;
          }
          .order-status {
            margin-top: -5px;
            margin-bottom: -5px;
            margin-left: 0px;
          }
        }

        &__summ {
          margin-top: -3px;
          padding-left: 97px;
        }
      }

    }
  }
}

/* –––––––––––––––––––––––––––– */
/* ––––––– SMALL DESKTOP –––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 1279px) {

  .cart {

    &-row {
      padding-top: 40px;
      padding-bottom: 40px;
      padding-left: 10px;

      &__checkout-btn {
        padding-top: 30px;
        padding-bottom: 5px;
      }
      &__checkout-btn + &__promo-codes {
        .promo-codes {
          margin-top: -70px;
        }
      }
    }
  }

  /*** account : orders ***/
  .account-order {
    .cart {
      &-row {
        padding-top: 44px;
        padding-bottom: 33px;
      }
      &-footer {
        padding-top: 20px;
        padding-bottom: 25px;

        .order-details {
          &__info {
            width: 100%;
            padding-bottom: 27px;
            margin-bottom: 20px;
            border-bottom: 1px solid #f3f3f3;
            td {
              line-height: normal;
              padding-right: 0;
            }
          }

          &__summ {
            margin-top: 2px;
            padding-left: 0;
            & td {
              padding-right: 64px;
            }
          }
        }

      }
    }
  }

}

/* –––––––––––––––––––––––––––– */
/* –––––––––– TABLET –––––––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 1023px) {

  .cart {
    margin-top: 20px;

    &-row {
      padding-top: 44px;
      padding-bottom: 44px;
      padding-left: 0;

      &__order-details {
        padding-top: 40px;
        padding-bottom: 34px;
      }
      &__checkout-btn {
        padding-top: 30px;
        padding-bottom: 35px;
      }
      &__checkout-btn + &__promo-codes {
        .promo-codes {
          margin-top: 0;
        }
      }
    }
  }

  .account-order {
    .cart {
      &-footer {
        .order-details {
          &__info {
            td:first-child {
              width: 23%;
            }
          }
        }
      }
    }
  }
}


/* –––––––––––––––––––––––––––– */
/* –––––––––– MOBILE –––––––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 767px) {

  .cart {
    margin-top: 0;

    &-row {
      padding-bottom: 38px;

      &__order-details {
        padding-bottom: 27px;
      }
      &__checkout-btn {
        padding-top: 0;
        padding-bottom: 62px;
      }
      &__checkout-btn + &__promo-codes {
        .promo-codes {
          margin-top: -30px;
          margin-bottom: 30px;
        }
      }
    }
    &-row&-item {
      padding-left: 88px;
    }
  }/* end cart */

  .account-order {
    .cart-footer {
      .order-details__summ {
        margin-left: 0;
        margin-right: 0;
        width: 100%;
      }
      .order-details__info {
        & td:first-child {
          width: 55%;
        }
        &:after {
          display: none;
        }
      }

    }
  }

}
