.slider-masters {
  margin-bottom: -13px;
  margin-top: 16px;

  & .slick-list {
    margin-left: -13px;
    margin-right: -13px;
  }

  & .slick-arrow {
    position: absolute;
    top: -88px;
  }
  & .slick-prev {
    right: 20px;
  }
  & .slick-next {
    right: -10px;
  }

  &__slide {
    position: relative;
    margin: 37px 13px 0 13px;
    border: 1px solid #f3f3f3;
    padding: 60px 30px 37px;
    text-align: center;
  }

  &__image {
    width: 74px;
    height: 74px;
    border-radius: 50%;
    overflow: hidden;
    background-size: cover;
    background-position: center center;
    position: absolute;
    top: -37px;
    left: 50%;
    transform: translateX(-50%);
  }
  &__name {
    display: block;
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  &__desc {
    font-size: 13px;
    line-height: 20px;
    color: #545454;
  }
  &__tel {
    display: block;
    margin-top: 15px;
    font-size: 13px;
    font-weight: 700;
  }
}

/* –––––––––––––––––––––––––––– */
/* –––––– SMALL DESKTOP ––––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 1279px) {

  .slider-masters {
    margin-top: 50px;
  }

}



/* –––––––––––––––––––––––––––– */
/* –––––––––– TABLET –––––––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 1023px) {

  .slider-masters {
    padding-bottom: 25px;
    margin-bottom: 0;

    &__slide {
      padding-left: 40px;
      padding-right: 40px;
    }
  }

}


/* –––––––––––––––––––––––––––– */
/* –––––––––– MOBILE –––––––––– */
/* –––––––––––––––––––––––––––– */
@media(max-width: 767px) {

  .slider-masters {
    padding-bottom: 90px;
    margin-top: 40px;

    & .slick-arrow {
      height: 95px;
      width: 100px;
      bottom: 3px;
      top: auto;
      background-size: 14px 20px;
    }
    & .slick-prev {
      right: calc(50% + 13px);
      background-position: center right;
    }
    & .slick-next {
      left: calc(50% + 13px);
      background-position: center left;
    }

  }

}
