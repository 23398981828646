.news-list {
  margin-top: 62px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-left: -13px;
  margin-right: -13px;

  .grid-row {
    display: flex;
    width: 100%;
  }
}

@media (max-width: 767px) {
  .news-list {
    margin-top: 48px;
  }
}
