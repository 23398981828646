.advantages {
  display: flex;
  min-height: 112px;
  border-top: 1px solid #f3f3f3;
  border-bottom: 1px solid #f3f3f3;
  margin-top: 70px;
  margin-bottom: 70px;
  padding: 40px 10px 30px;

  &--product-card {
    margin-top: 110px;
    margin-bottom: 58px;
  }

  &__item {
    display: flex;
  }

  &__icon {
    fill: #ff7600;

    &--1 {
      /*width: 65px;*/
      width: 52px;
      height: 34px;
      margin-left: -3px;
      margin-top: -3px;
      margin-right: 18px;
    }
    &--2 {
      /*width: 72px;*/
      width: 54px;
      height: 32px;
      /*margin-left: 51px;*/
      margin-left: 64px;
      margin-right: 18px;
    }
    &--3 {
      /*width: 74px;*/
      width: 55px;
      height: 32px;
      margin-right: 20px;
      /*margin-left: 37px;*/
      margin-left: 38px;
    }
    &--4 {
      width: 70px;
      height: 34px;
      /*margin-left: 47px;
      margin-right: 18px;*/
      margin-left: 45px;
      margin-right: 12px;
    }
  }

  &__text {
    color: #545454;
    line-height: 16px;
  }
}


/* –––––––––––––––––––––––––––– */
/* ––––––– SMALL DESKTOP –––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 1279px) {

  .advantages {
    min-height: 127px;

    &--product-card {
      margin-top: 58px;
    }

    &__icon {
      &--1 {
        width: 65px;
        /*margin-right: 18px;*/
      }
      &--2 {
        width: 72px;
        margin-left: 51px;
        /*margin-left: 64px;*/
      }
      &--3 {
        width: 74px;
        margin-left: 37px;
        /*margin-left: 38px;*/
      }
      &--4 {
        margin-left: 47px;
        margin-right: 18px;
        /*margin-left: 45px;
        margin-right: 12px;*/
      }
    }
  }

}

/* –––––––––––––––––––––––––––– */
/* –––––––––– TABLET –––––––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 1023px) {

  .advantages {
    margin-top: 51px;
    min-height: 173px;

    &__item {
      flex-direction: column;
    }

    &__icon {
      &--1 {
        margin-top: -2px;
        margin-left: 0;
        width: 33px;
        margin-bottom: 14px;
      }

      &--2 {
        margin-bottom: 14px;
        margin-left: 24px;
        margin-top: 9px;
        height: 23px;

        & + .advantages__text {
          padding-left: 42px;
        }
      }

      &--3 {
        margin-left: 34px;
        margin-top: 5px;
        margin-bottom: 9px;
        width: 37px;

        & + .advantages__text {
          padding-left: 34px;
        }
      }

      &--4 {
        margin-left: 29px;
        margin-bottom: 14px;
        margin-top: -2px;

        & + .advantages__text {
          padding-left: 48px;
        }
      }

    }
  }

}

/* –––––––––––––––––––––––––––– */
/* –––––––––– MOBILE –––––––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 767px) {

}
