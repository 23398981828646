/* Plugin author styles. Do not change! */

.slick-slider {
  position: relative;
  display: block;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: '';
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  /*height: 100%;*/
  min-height: 1px;
  outline: none;
}

[dir='rtl'] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  /*border: 1px solid transparent;*/
}

.slick-arrow.slick-hidden {
  display: none;
}




/* Visual styles */

.slick-slide img {
  max-width: 100%;
}

.slick-arrow {
  outline: none;
  text-indent: 1000px;
  overflow: hidden;
  width: 30px;
  height: 50px;
  background-clip: content-box;
  background-origin: content-box;
  background-color: transparent;
  background-size: 16px 16px;
  background-repeat: no-repeat;
  background-position: center center;
  border: none;
  cursor: pointer;

  &:hover,
  &:active,
  &:focus {
    background-size: 16px 16px;
  }
  &.slick-disabled {
    opacity: .25;
    cursor: default;
  }
}

.slick-prev {
  background-image: svg('arrow-left', '[fill]: #9a9a9a');

  &:hover {
    background-image: svg('arrow-left', '[fill]: #474747');
  }
}

.slick-next {
  background-image: svg('arrow-right', '[fill]: #9a9a9a');

  &:hover {
    background-image: svg('arrow-right', '[fill]: #474747');
  }
}

.slick-dots {
  position: absolute;
  list-style: none;
  margin: 0;
  padding: 0;
  & li {
    position: relative;
    display: inline-block;
    margin-left: 6px;
    margin-right: 6px;
    box-shadow: 0 0 2px 2px rgba(255,255,255,.1);
    background: rgba(0,0,0,.3);
    width: 7px;
    height: 7px;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
    transition: box-shadow .35s ease-out, background .45s ease-out;

    &:hover {
      background: rgba(0,0,0,.65);
    }
    &:before {
      display: none;
    }
    &.slick-active {
      background: rgba(0,0,0,1);
      transition: box-shadow .3s ease-in, background .3s ease-in;
    }
    & button {
      display: none;
    }
  }
}

.slick-slider {
  opacity: 0;
  transition: opacity .55s;

  &.slick-initialized {
    opacity: 1;
  }
}
