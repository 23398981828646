.compare {
  &__wrapper {
    padding-bottom: 20px;
  }
  &__inner {
    margin-right: -26px;
    padding-right: 26px;
    overflow: hidden;
  }
  /*** params of compare ***/
  &__filter {
    margin-bottom: 1px;

    & .chosen-container.chosen-container-single .chosen-single {
      height: 35px;
      line-height: 35px;
    }
  }
  &__params-table {
    padding-top: 45px;
    margin-left: -3px;
    width: calc(100% + 26px + 6px);

    &-text {
      display: block;
      padding-top: 10px;
      padding-bottom: 10px;
      margin-top: 3px;
      margin-bottom: 4px;
      font-size: 13px;
      color: #545454;
      font-weight: 700;
      white-space: nowrap;
    }
    & tr.diff &-text {
      background-color: #fdfbf9;
    }
  }

  /*** value of compare ***/
  &__body-inner {
    position: relative;
    z-index: 2;

    & .mCS-dark-thick.mCSB_scrollTools {
      & .mCSB_dragger .mCSB_dragger_bar,
      & .mCSB_draggerRail {
        border-radius: 0;
      }
      & .mCSB_draggerRail {
        background-color: #f3f3f3;
      }
    }
    & .mCS-dark-thick.mCSB_scrollTools_horizontal {
      & .mCSB_dragger .mCSB_dragger_bar {
        height: 4px;
        margin: 6px auto;
        background-color: #cbcbcb;
      }
    }
    & .mCSB_horizontal.mCSB_inside > .mCSB_container {
      margin-bottom: 48px;
    }
  }
  &__value-table {

    &-text {
      display: block;
      padding-top: 10px;
      padding-bottom: 10px;
      margin-top: 3px;
      margin-bottom: 4px;
      font-size: 13px;
      color: #545454;
      white-space: nowrap;
    }
    & tr.diff &-text {
      background-color: #fdfbf9;
      margin-left: -4px;
      padding-left: 4px;
    }
  }

  &__preview {
    & th,
    & td {
      padding: 0 26px 0 0;

      &:last-child {
        padding: 0;
      }
    }
  }
}


/* –––––––––––––––––––––––––––– */
/* –––––––– BIG DESKTOP ––––––– */
/* –––––––––––––––––––––––––––– */
@media (min-width: 1280px) {

  .compare {

    /*** params of compare ***/
    &__inner {
      padding-top: 410px;
    }

    /*** value of compare ***/
    &__value-table {
      & th,
      & td {
        width: 298px;
        &:last-child {
          width: calc(298px - 26px);
        }
      }
    }
  }

}

/* –––––––––––––––––––––––––––– */
/* ––––––– SMALL DESKTOP –––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 1279px) {

  .compare {
    &__wrapper {
      margin-bottom: 40px;
    }
    /*** params of compare ***/
    &__inner {
      padding-top: 350px;
    }

    /*** value of compare ***/
    &__value-table {
      & th,
      & td {
        width: 240px;
        &:last-child {
          width: calc(240px - 26px);
        }
      }
    }
  }

}

/* –––––––––––––––––––––––––––– */
/* –––––––––– TABLET –––––––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 1023px) {

  .compare {
    &__wrapper {
      margin-top: -4px;
      margin-bottom: 35px;
    }
    /*** value of compare ***/
    &__value-table {
      & th,
      & td {
        width: 237px;
        &:last-child {
          width: calc(237px - 26px);
        }
      }
    }
  }

}


/* –––––––––––––––––––––––––––– */
/* –––––––––– MOBILE –––––––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 767px) {

  .compare {
    &__wrapper {
      display: none;
    }
  }

}
