.blog-item {
  height: 380px;
  position: relative;
  overflow: hidden;
  margin-top: 18px;
  /*margin-bottom: 35px;*/
  margin-bottom: 52px;

  &__link {
    display: block;
    text-decoration: none;
    transition: color .45s;
    position: relative;
    overflow: hidden;
  }
  &__heading.link {
    display: block;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    letter-spacing: .1em;
    font-weight: 600;
    margin-bottom: 14px;
    margin-top: 0;
    max-height: 38px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__desc {
    color: #434a54;
    line-height: 22px;
  }
  &__image {
    width: 100%;
    padding-bottom: 32px;
  }
  &__date {
    color: #9a9a9a;
    font-size: 12px;
    font-style: italic;
  }
  &__category {
    color: #a1a1a1;
    font-size: 12px;
    font-style: italic;
    margin-top: 10px;
    cursor: default;
  }
  &__summary {
    line-height: 20px;
    margin-top: 8px;
    max-height: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  /*** medium size ***/
  &--medium {

    .blog-item {
      &__date,
      &__summary {
        display: none;
      }
      &__link {
        height: 100%;
      }
      &__image {
        display: block;
        max-width: none !important;
        width: auto;
        min-width: 100%;
        height: 100%;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        margin-right: -50%;
        padding-bottom: 0;
      }
      &__heading {
        position: absolute;
        bottom: 0;
        font-size: 24px;
        line-height: 28px;
        padding: 25px 28px;
        margin-bottom: 0;
        color: #fff;
        &.link {
          max-height: none;
        }
      }
    }
  }

  /*** full size ***/
  &--full {
    padding-top: 56px;
    padding-bottom: 56px;
    margin-top: 9px;
    margin-bottom: 37px;

    &:before,
    &:after {
      content: '';
      position: absolute;
      left: 13px;
      right: 13px;
      height: 1px;
      background: #f3f3f3;
    }
    &:before {
      top: 0;
    }
    &:after {
      bottom: 0;
    }

    .blog-item {
      &__link {
        width: 48.8%;
        height: 100%;
        float: left;
        margin-right: 26px;
      }
      &__heading {
        margin-top: 17px;
        margin-bottom: 18px;
      }
      &__summary {
        max-height: 120px;
        margin-top: 14px;
        margin-bottom: 27px;
      }
      &__btn {
        margin-left: calc(48.8% + 26px);
        width: 168px;
      }
    }
  }

  /*** full blog item / main page ***/
  &--main-page {
    margin-top: 0;
    padding-top: 14px;

    &:before {
      display: none;
    }
    .blog-item {
      &__image {
        padding-bottom: 0;
      }
    }
  }
}



/* –––––––––––––––––––––––––––– */
/* –––––––– BIG DESKTOP ––––––– */
/* –––––––––––––––––––––––––––– */
@media (min-width: 1280px) {

  .blog-item {

    /*** medium size ***/
    &--medium {
      height: 472px;
    }
    &--medium + & {
      height: 472px;
    }

    /*** full size ***/
    &--full {
      height: 456px;
    }

    /*** full blog item / main page ***/
    &--main-page {
      height: 420px;
    }
  }

}

/* –––––––––––––––––––––––––––– */
/* ––––––– SMALL DESKTOP –––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 1279px) {

  .blog-item {

    /*** full size ***/
    &--full {
      height: 392px;
    }

    /*** full blog item / main page ***/
    &--main-page {
      height: 350px;
    }
  }

}

/* –––––––––––––––––––––––––––– */
/* –––––––––– TABLET –––––––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 1023px) {

  .blog-item {
    height: 374px;

    &--medium {
      height: 430px;
    }
    &--full {
      .blog-item {
        &__image {
          display: block;
          max-width: none !important;
          width: auto;
          min-width: 100%;
          height: 100%;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          margin-right: -50%;
          padding-bottom: 0;
        }
        &__summary {
          max-height: 80px;
        }
      }
    }

    &--full&--main-page {
      height: 374px;
      padding-top: 0;
      padding-bottom: 0;
      margin-top: 18px;
      margin-bottom: 52px;

      &:before,
      &:after {
        display: none;
      }

      .blog-item {
        &__link {
          width: 100%;
          height: auto;
          float: none;
          margin-right: 0;
        }
        &__image {
          max-width: 100%;
          width: 100%;
          height: auto;
          position: relative;
          left: auto;
          transform: none;
          margin-right: 0;
          padding-bottom: 32px;
        }
        &__heading {
          margin-bottom: 14px;
          margin-top: 0;
        }
        &__summary {
          margin-top: 8px;
          margin-bottom: 0;
          max-height: 80px;
        }
      }
    }
  }

}


/* –––––––––––––––––––––––––––– */
/* –––––––––– MOBILE –––––––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 767px) {
  .blog-item {
    height: auto;
    margin-bottom: 70px;

    &--medium {
      height: 350px;
      margin-left: -7px;
      margin-right: -7px;
      padding: 0;
      .blog-item {
        &__heading {
          padding-left: 20px;
          padding-right: 20px;
        }
      }
    }

    &--full {
      padding-top: 0;
      padding-bottom: 0;

      &:before,
      &:after {
        display: none;
      }

      .blog-item {
        &__link {
          width: 100%;
          /*height: 100%;*/
          float: none;
          margin-right: 0;
        }
        &__image {
          width: 100%;
          min-width: 100%;
          height: auto;
          position: relative;
          left: auto;
          transform: translateX(0);
          margin-right: 0;
        }
        &__heading {
          margin-top: 0;
          margin-bottom: 14px;
        }
        &__summary {
          margin-top: 8px;
          margin-bottom: 0;
        }
        &__btn {
          display: none;
        }
      }
    }

    &--full&--main-page {
      height: auto;

      .blog-item {
        &__image {
          padding-bottom: 0;
        }
        &__link {
          margin-bottom: 32px;
        }
        &__summary {
          max-height: 100px;
        }
      }
    }


    &__image {
      padding-bottom: 0;
    }
    &__link {
      margin-bottom: 32px;
    }
    &__summary {
      max-height: 100px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}
