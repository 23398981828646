.account-menu {
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  text-align: center;
  align-items: stretch;
  box-shadow: inset 0 0 0 1px #f3f3f3;
  margin-top: 64px;

  /*&:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: #eee;
  }
  &:after{
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: #eee;
  }*/

  &__user {
    position: relative;
    padding-top: 56px;
    padding-bottom: 27px;
    box-shadow: inset 0 -1px 0 0 #f3f3f3;

    &-pic {
      width: 74px;
      height: 74px;
      border-radius: 50%;
      overflow: hidden;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: -37px;
      cursor: pointer;

      & img {
        display: block;
        min-height: 100%;
        width: auto;
        min-width: 100%;
      }

      &-edit {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(255,255,255,.75);
        opacity: 0;
        visibility: hidden;
        transition: opacity .55s;

        &-text {
          font-size: 11px;
          line-height: 11px;
          padding-top: 4px;
          color: #545454;
        }
        &-icon {
          width: 14px;
          height: 14px;
        }
      }

      &:hover &-edit {
        opacity: 1;
        visibility: visible;
      }
    }

    &-name {
      font-size: 15px;
      font-weight: 600;
    }
  }

  &__item {
    box-shadow: inset 0 -1px 0 0 #f3f3f3;
  }

  &__link {
    display: block;
    font-size: 11px;
    line-height: 48px;
    padding-top: 1px;
    padding-bottom: 2px;
    text-transform: uppercase;

    &:hover {
      color: #ff7600;
    }

    &.link {
      letter-spacing: .1em;
    }
  }
  &__item--current &__link {
    color: #ff7600;
  }

  &__info {
    font-size: 11px;
    line-height: 20px;
    color: #545454;
    padding: 26px 28px 30px;

    & .link {
      margin-left: 5px;
      margin-right: 5px;
    }
  }

  &__forum {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 19px;
    padding-bottom: 7px;

    &-registred {
      font-size: 11px;
      line-height: 20px;
      color: #545454;
    }
    &-msgs {
      font-size: 11px;
      line-height: 20px;
      color: #545454;
    }
    &-send {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 14px;

      &-icon {
        width: 15px;
        height: 10px;
        fill: #474747;
        margin-right: 10px;
      }
      &:hover &-icon {
        fill: #ff7600;
      }
      &-text {
        font-size: 11px;
        line-height: 13px;
        border-bottom: 1px solid #000;
      }
      &:hover &-text {
        color: #ff7600;
        border-color: #ff7600;
      }
    }

  }
}

.account__discont {
  text-align: center;
  padding: 0 30px;

  &-title {
    margin-bottom: 5px;
  }
  &-text {
    margin-bottom: 20px;
    color: #545454;
    font-size: 12px;
    line-height: 18px;
  }
}

/* –––––––––––––––––––––––––––– */
/* –––––––––– TABLET –––––––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 1023px) {

  .account-menu {
    margin-top: 11px;
    margin-bottom: 32px;

    &__list {
      display: flex;
      flex-direction: row;
    }

    &__item {
      width: 33.3333%;

      &:nth-child(2) {
        box-shadow: inset -1px 0 0 0 #f3f3f3, inset 1px 0 0 0 #f3f3f3;
      }
    }

    &__link {
      padding-top: 3px;
      padding-bottom: 1px;
    }

  }

}

.change-avatar {
  &__wrapper {
    padding-bottom: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .account-menu__user-pic {
      position: relative;
      left: auto;
      top: auto;
      transform: translate(0);
      margin-bottom: 20px;
      cursor: default;
    }
  }
}

/* –––––––––––––––––––––––––––– */
/* –––––––––– MOBILE –––––––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width:767px) {

  .account-menu {
    margin: -1px -20px 21px;
    box-shadow: inset 0 1px 0 0 #f3f3f3;

    &__list {
      flex-direction: column;
    }
    &__item,
    &__item:nth-child(2) {
      width: 100%;
      box-shadow: inset 0 -1px 0 0 #f3f3f3;
    }

  }

  .account__discont {
    text-align: left;
    padding: 5px 0;
  }

}
