.customscroll {
  max-height: 320px;
}
.mCustomScrollBox {
  outline: none !important;
  height: auto;
}
.mCSB_inside > .mCSB_container {
  margin-right: 4px;
}

.mCSB_scrollTools {
  width: 4px;

  /*** bar ***/
  .mCSB_draggerRail {
    width: 4px;
    border-radius: 0;
    background-color: #f3f3f3;
  }

  /*** handler ***/
  .mCSB_dragger {
    .mCSB_dragger_bar {
      border-radius: 0;
      background-color: #cbcbcb;
    }

    /* hover */
    &:hover {
      .mCSB_dragger_bar {
        background-color: #cbcbcb;
      }
    }

    /* active/focus */
    &:active,
    &.mCSB_dragger_onDrag {
      .mCSB_dragger_bar {
        background-color: #cbcbcb;
      }
    }
  }

}
