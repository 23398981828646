:root .slider-product-thumb {
  margin: 25px auto 0;
  width: calc(100% - 60px - 26px);
  
  &:not(.slick-initialized) {
    display: none;
  }

  &__slide {
    background-size: cover;
    background-position: center;
    height: 74px;
    width: 74px;
    opacity: .65;
    margin: 0 10px;

    &-image {
      display: block;
      height: 100%;
      width: auto;
      margin: 0 auto;
      padding-top: 0;
    }
    &.slick-current {
      opacity: 1;
    }
  }
  .slick-arrow {
    background-size: 7px 13px;
    transform: rotate(0deg);
    position: absolute;
    top: 18px;
  }
  .slick-prev {
    left: -45px;
  }
  .slick-next {
    right: -45px;
  }
}

/* –––––––––––––––––––––––––––– */
/* ––––––– SMALL DESKTOP –––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 1279px) {

  :root .slider-product-thumb {

    &__slide {
      height: 55px;
      width: 55px;
    }

    & .slick-arrow {
      top: 0;
    }
  }

}
