:root .slider-jumbotron {
  width: 100%;
  border-left: 1px solid #f3f3f3;
  border-right: 1px solid #f3f3f3;

  &__wrapper {
    width: 1174px;
    margin: 0 auto;
    padding-bottom: 22px;
    position: relative;
  }

  &__slide {
    display: flex;
    height: 540px;
    background-size: cover;
    background-position: center;
    text-decoration: none;
    justify-content: center;
    align-items: center;
  }
  /*&--shop &__slide {
    height: 600px;
  }*/
  &__content {
    width: 58%;
    margin-top: -53px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
  }
  &__heading {
    text-transform: uppercase;
    letter-spacing: .1em;
    line-height: 32px;
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 23px;
  }
  &__description {
    font-size: 16px;
    line-height: 25px;
    color: #545454;
  }
  &__btn {
    width: 275px;
    margin-top: 25px;
  }


  & .slick-dots {
    bottom: 16px;
    left: 50%;
    transform: translateX(-50%);
    margin-right: -50%;
  }

  /***** thumbs *****/
  &-thumb {
    height: 82px;

    margin-top: -82px;

    & .slick-list {
      padding-top: 10px !important;
      border-left: 1px solid #f3f3f3;
      border-right: 1px solid #f3f3f3;
    }
    & .slick-track {
      display: flex;
      flex-wrap: nowrap;
      height: 72px;
      border-top: 1px solid #f3f3f3;
      border-bottom: 1px solid #f3f3f3;
      background-color: rgba(255,255,255,.9);
    }

    &__slide {
      height: 70px;
      padding-top: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      font-size: 13px;
      color: #9a9a9a;
      transition: color .45s;
      cursor: pointer;

      &.slick-active {

        &.slick-center {
          color: #000;
        }
      }
    }

    &__heading {
      text-transform: uppercase;
      letter-spacing: .1em;
      font-weight: 600;
    }

    &__arrow {
      position: absolute;
      bottom: 93px;
      left: 50%;
      transform: translateX(-50%);
      width: 21px;
      height: 9px;
      overflow: hidden;
      z-index: 3;

        &:before {
          content: '';
          width: 12px;
          height: 12px;
          border: 1px solid #f3f3f3;
          position: absolute;
          top: 2px;
          left: 4px;
          transform: rotate(-45deg);
          background-color: rgba(255,255,255,.9);
        }

    }
  }/* end thumb */

}/* end jumbotron */

/* –––––––––––––––––––––––––––– */
/* ––––––– SMALL DESKTOP –––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 1279px) {

  :root .slider-jumbotron {

    &__wrapper {
      width: 946px;
    }
    &__content {
      width: 73%;
    }
  }

}

/* –––––––––––––––––––––––––––– */
/* –––––––––– TABLET –––––––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 1023px) {

  :root .slider-jumbotron {

    &__wrapper {
      width: 100%;
    }
    &__content {
      width: 90%;
      margin-top: 5px;
    }
  }

}

/* –––––––––––––––––––––––––––– */
/* –––––––––– MOBILE –––––––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 767px) {

  :root .slider-jumbotron {
    &__wrapper {
      padding-bottom: 15px;
    }
    &__content {
      width: 85%;
      margin-top: -5px;
    }
    &__heading {
      line-height: 36px;
    }
  }

}
