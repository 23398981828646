.chosen-container {
  width: 100%!important;
  font-size: 13px;

  &.chosen-container-single {

    /* placeholder */
    .chosen-default {
      color: #a4a4a4;
    }

    /* select body */
    .chosen-single {
      height: 45px;
      line-height: 45px;
      border: 1px solid #ececec;
      padding-left: 18px;

      /* text */
      & span {
        margin-right: 38px;
      }

      /* arrow wrapper */
      & div {
        width: 38px;

        & b {
          /*background-position: 0 6px;*/

          /* arrow */
          &:after {
            content: "";
            display: block;
            position: absolute;
            top: 50%;
            margin-top: -1px;
            right: 14px;
            width: 0;
            height: 0;
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
            border-top: 4px solid #474747;
            border-bottom: 0 solid #474747;
          }
        }
      }
    }
  }
}

/***** dropdown *****/
.chosen-container {

  /***** dropdown *****/
  .chosen-drop {
    border: 1px solid #ececec;
    margin-top: -2px;

    .chosen-drop-wrapper {
      max-height: 160px;
      background: #fff;
      overflow: hidden;
    }
  }
  .chosen-results {
    padding: 10px 0;
    margin: 0;
    color: #545454;
    overflow: hidden!important;
    max-height: none!important;

    & li {
      line-height: 20px;
      padding: 5px 18px;
      margin-bottom: 5px;

      /* hover */
      &.highlighted {
        background: #fdfbf9;
        color: #545454;
      }

      /* selected */
      &.result-selected {
        font-weight: 600;
      }

    }
  }/*end dropdown*/
}

/* default select */
:root .chosen-select {
  background-color: transparent;
  position: relative;
  background-image: svg('select-arrow.svg');
  background-size: 9px 6px;
  background-position: 95% center;
  background-repeat: no-repeat;
  padding-right: 45px;

}

/*.chosen-container-active.chosen-with-drop .chosen-single div b{
  background-position: -18px 6px;
}*/

/***** custom properties *****/

/* editable address */
.address-select {
  .chosen-container .chosen-results li {
    padding-right: 60px;
  }
}

/* blog */
.blog {
  .chosen-container-single {
    margin-bottom: 15px;
  }
}
