.size-selector,
.color-selector {
  display: flex;
  flex-direction: column;
  margin-right: 5px;

  &__label {
    line-height: 34px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: .1em;
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    background-position: center;
    background-size: contain;;
    background-color: transparent;
    background-repeat: no-repeat;
    text-align: center;
    cursor: pointer;
    outline: none;
    text-transform: uppercase;
    transition: box-shadow .55s;
    border: 0;
    cursor: pointer;



  }
}

.color-selector {

  &__item {
    min-width: 43px;
    height: 43px;
    padding: 0 5px;
    margin-top: 5px;
    margin-right: 15px;
    line-height: 45px;
    background-color: transparent;
    box-shadow: 0 0 0 1px #fff;
    transition: box-shadow .55s;

    &:hover {
      box-shadow: 0 0 0 1px #cbcbcb;
    }
    &--current {
      box-shadow: 0 0 0 1px #cbcbcb;
    }
    &--disabled {
      color: #999;
      opacity: .3;
      cursor: default;
      &:hover {
        box-shadow: 0 0 0 1px #fff;
      }
    }
  }
}
.size-selector {
  &__label {
    font-size: 12px;
    letter-spacing: .1em;
    line-height: 20px;
    margin-top: 16px;
  }
  &__items {
    margin-left: -6px;
  }
  &__item {
    min-width: 30px;
    height: 30px;
    padding: 5px;
    margin: 13px 5px 0;
    font-size: 12px;
    transition: opacity .45s;

    &:hover {
      opacity: .75;
    }
    &--current {
      box-shadow: inset 0 0 0 1px #bababa;
    }
    &--disabled {
      opacity: .4;
      cursor: default;

      &:hover {
        box-shadow: none;
      }
    }
  }
}

@media (max-width: 767px) {

  .color-selector {
    &__label {
      line-height: 26px;
    }
    &__items {
      margin-left: 2px;
    }
    &__item {
      margin-top: 8px;
    }
  }
}
