.slider-tile {
  height: 426px;
  margin-left: -13px;
  margin-right: -13px;
  margin-bottom: 49px;
  position: relative;

  &__slide {
    display: block;
    padding-left: 13px;
    padding-right: 13px;
    position: absolute;

    &:nth-child(1) {
      width: 50%;
      height: 426px;
      left: 0;
      top: 0;
    }
    &:nth-child(2) {
      width: 25%;
      height: 200px;
      /*margin-bottom: 26px;*/
      left: 50%;
      top: 0;
    }
    &:nth-child(3) {
      width: 25%;
      height: 200px;
      left: 50%;
      top: calc(50% + 13px);
    }
    &:nth-child(4) {
      width: 25%;
      height: 426px;
      left: 75%;
      top: 0;
    }

    &-image {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center center;
    }
    &-heading {
      text-transform: uppercase;
      padding: 15%;
      text-align: center;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: .1em;
      color: #000;
      opacity: 0;
      visibility: hidden;
      transition: opacity .55s;
      position: absolute;
      left: 13px;
      right: 13px;
      bottom: 0;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(255,255,255,.9);
      z-index: 1;
    }
    &:hover &-heading {
      opacity: 1;
      visibility: visible;
    }

  }
}

/* –––––––––––––––––––––––––––– */
/* ––––––– SMALL DESKTOP –––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 1279px) {

  .slider-tile {
    height: 342px;

    &__slide {
      &:nth-child(1) {
        height: 342px;
      }
      &:nth-child(2) {
        height: 158px;
      }
      &:nth-child(3) {
        height: 158px;
      }
      &:nth-child(4) {
        height: 342px;
      }
    }
  }

}


/* –––––––––––––––––––––––––––– */
/* ––––––– < than TABLET –––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 1023px) {

  .slider-tile {
    height: 426px;
    position: relative;
    margin-bottom: 60px;

    &__slide {
      position: absolute;

      &:nth-child(1) {
        width: 75%;
        height: 200px;
        left: 0;
        top: 0;
      }
      &:nth-child(2) {
        width: 50%;
        height: 200px;
        /*margin-bottom: 26px;*/
        left: 0;
        top: 226px;
      }
      &:nth-child(3) {
        width: 50%;
        height: 200px;
        left: 50%;
        top: 226px;
      }
      &:nth-child(4) {
        width: 25%;
        height: 200px;
        left: 75%;
        top: 0;
      }
    }

    &.slick-initialized {
      height: auto;
      margin-left: 0;
      margin-right: 0;

      .slick-list {
        padding-bottom: 60px;
      }

      .slick-dots {
        left: 50%;
        transform: translateX(-50%);
        bottom: -20px;
      }

      .slider-tile {
        &__slide {
          position: relative;
          left: auto;
          top: auto;
          width: 100%;
          max-width: 100%;
          height: 0;
          padding-top: 18.52%;
          padding-left: 0;
          padding-right: 0;

          &-image {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
          }

          &-heading {
            top: calc(100% + 16px);
            left: 0;
            right: 0;
            background-color: transparent;
            opacity: 1;
            visibility: visible;
            padding: 0;
            height: 40px;
            font-size: 16px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }

    }/* end initialized styles */
  }

}

/* –––––––––––––––––––––––––––– */
/* –––––––––– TABLET –––––––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 1023px) {

}

/* –––––––––––––––––––––––––––– */
/* –––––––––– MOBILE –––––––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 767px) {

  .slider-tile {
    &.slick-initialized {
      padding-top: 50px;
      padding-bottom: 75px;
      margin-bottom: 0;
      position: relative;

      &:before {
        display: block;
        content: '';
        position: absolute;
        left: -20px;
        right: -20px;
        height: 1px;
        background: #f3f3f3;
      }
      &:before {
        bottom: 0;
      }

      .slick-dots {
        bottom: 56px;
      }
    }
  }

}
