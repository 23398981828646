.blog {

  /*** blog list ***/
  &__select {
    margin-bottom: 40px;
  }
  &__list {
    .catalog-grid__load-more {
      padding-top: 18px;
      padding-bottom: 1px;
      margin-bottom: 0;
    }
  }
  &__load-more {
    margin-top: 25px;
  }
  .chosen-select {
    margin-bottom: 10px;
  }

  /*** blog post ***/
  &__date {
    font-size: 13px;
    line-height: 20px;
    color: #9a9a9a;
    font-style: italic;
    text-align: center;
    margin-top: -30px;
    margin-bottom: 28px;
  }

  &__slider {
    height: 596px;
    overflow: hidden;
  }

  &__text-body {
    color: #545454;

    & p {
      font-size: 14px;
      line-height: 20px;
    }
    .p--styled {
      font-size: 18px;
      font-style: italic;
      margin-top: 51px;
      margin-bottom: 74px;
      line-height: 25px;
    }
    & h2 {
      margin-top: 52px;
    }
  }
  &__paginator {
    padding-top: 0px;
    padding-bottom: 30px;

    & .paginator {
      margin-bottom: 0;
    }
  }

}


/* –––––––––––––––––––––––––––– */
/* ––––––– SMALL DESKTOP –––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 1279px) {

  .blog {

    /*** blog post ***/
    &__slider {
      height: 578px;
      overflow: hidden;
    }
    &__text-body {
      .p--styled {
        margin-top: 58px;
      }
    }
    &__paginator {
      padding-top: 7px;
      padding-bottom: 59px;
    }

    /*** blog list ***/
    &__list {
      .catalog-grid__load-more {
        padding-top: 1px;
      }
    }
  }

}


/* –––––––––––––––––––––––––––– */
/* –––––––––– TABLET –––––––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 1023px) {

  .blog {

    /*** blog post ***/
    &__slider {
      height: 428px;
    }
    &__paginator {
      padding-top: 0;
      padding-bottom: 39px;
      margin-top: -3px;
    }

    /*** blog list ***/
    &__list {
      margin-top: -8px;

      .catalog-grid__load-more {
        padding-top: 34px;
        padding-bottom: 32px;
      }
    }
  }

}


/* –––––––––––––––––––––––––––– */
/* –––––––––– MOBILE –––––––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 767px) {

  .blog {

    /*** post ***/
    &__date {
      margin-top: -16px;
      margin-bottom: 32px;
    }
    &__slider {
      height: 540px;
      margin-left: -20px;
      margin-right: -20px;
    }
    &__text-body {
      .p--styled {
        margin-top: 53px;
      }
      & h2 {
        margin-top: 58px;
        line-height: 28px;
      }
    }
    &__paginator {
      padding-top: 20px;
      padding-bottom: 32px;
    }

    /*** list ***/
    &__select {
      margin-top: -7px;
      margin-bottom: 33px;

      .chosen-container,
      .chosen-select {
        margin-bottom: 10px;
      }
    }
    &__list {
      .catalog-grid__load-more {
        padding-bottom: 40px;
        margin-top: -48px;
      }
    }
  }

}
