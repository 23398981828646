.btn {
  display: block;
  width: 100%;
  border: none;
  outline: none;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  margin-bottom: 10px;
  font-size: 12px;
  transition: background-color .45s, color .35s;

  &--primary {
    height: 35px;
    line-height: 35px;
    color: #fff;
    background-color: #ff7600;

    &:hover {
      background-color: #ff9133;
      color: #fff;
    }
  }

  &--added {
    color: #fff;
    background-color: #df6700;
    cursor: default;
    &:hover {
      background-color: #df6700;
      color: #fff;
    }
  }
  &--error {
    color: #fff;
    background-color: #ff4444;
    cursor: default;
    &:hover {
      background-color: #ff4444;
      color: #fff;
    }
  }
  &--not-available {
    color: #000;
    background-color: #f2f1ef;
    cursor: default;
    &:hover {
      background-color: #f2f1ef;
      color: #000;
    }
  }


  &--secondary {
    background-color: transparent;
    color: #727272;
    border: 1px solid #d1d1d1;
    height: 35px;
    line-height: 33px;
    transition: color .45s, border-color .35s;

    &:hover {
      color: #545454;
      border-color: #a7a7a7;
    }
  }


  &--inline {
    display: inline-block;
    vertical-align: top;
    width: auto;
  }
  &--sm {
    height: 30px;
    line-height: 30px;
  }
  &--lg {
    height: 48px;
    line-height: 48px;
  }

  &--no-margin-bottom {
    margin-bottom: 0;
  }
  &--small-margin-top {
    margin-top: 10px;
  }
  &--big-margin-top {
    margin-top: 45px;
  }


  &--link {
    height: auto;
    padding-right: 2px;
    line-height: 20px;
    font-size: 13px;
    letter-spacing: 0;
    color: #000;
    background: none;
    border: none;
    text-transform: none;
    text-decoration: none;
    font-weight: 400;
    transition: color .45s;
    position: relative;

    /*&:after {
      display: block;
      content: '';
      position: absolute;
      bottom: 1px;
      left: 1px;
      right: 0;
      height: 1px;
      background: rgba(0,0,0,.85);
      transition: background-color .45s;
    }*/

    &:hover {
      color: rgba(0,0,0,.65);
      box-shadow: none;

      /*&:after {
        background: rgba(0,0,0,.55);
      }*/
    }

  }
  &--centered {
    margin-left: auto;
    margin-right: auto;
    width: auto;
  }

  &--triangular {
    position: relative;
    &:after {
      content: '';
      width: 0;
    	height: 0;
      border-top: 18px solid transparent;
      border-left: 9px solid #ff7600;
      border-bottom: 17px solid transparent;
      position: absolute;
      right: -9px;
      top: 0px;
      transition: border-color .45s;
    }
    &:hover {
      &:after {
        border-left-color: #ff9133;
      }
    }
  }
}

.primary-btn__wrapper {
  button,
  input[type="submit"],
  input[type="button"] {
    display: block;
    height: 35px;
    width: 100%;
    border: none;
    outline: none;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    margin-bottom: 10px;
    font-size: 12px;
    line-height: 35px;
    color: #fff;
    background-color: #ff7600;
    transition: background-color .45s, color .35s;

    &:hover {
      background-color: #ff9133;
      color: #fff;
    }
  }
}
