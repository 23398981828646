/* masonry */

.masonry {
  margin-top: 30px;
  margin-left: -13px;
  margin-right: -13px;

  &__item {
    /*width: calc(($screen-xl - $gutter * 3)/3 - 6);*/
    float: left;
  }

  &:before,
  &:after {
     content: '';
     display: table;
   }
  &:after {
   clear: both;
  }
}

.masonry--4-columns {
  .masonry__item {
    width: calc(($screen-xl - $gutter * 4)/4 - 6);
  }
}

.download-more-wrapper {
  width: 100%;
}

@media (max-width: 767px) {
  .masonry {
    margin: 0 auto;
    width: 100%;
    &__item {
      width: 100%;
      float: none;
    }
  }

  .masonry--4-columns .masonry__item {
    width: 100%;
    text-decoration: none;
    padding: 0;
    margin: 0 0 40px 0;

    &:first-child {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        top: -40px;
        left: -30px;
        right: -30px;
        height: 1px;
        background: #eee;
      }
    }
  }
}
