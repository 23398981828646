.howmanyitems {
  display: inline-block;
  position: relative;
  width: 106px;
  height: 37px;
  padding: 0 27px;
  vertical-align: top;
  margin-top: -1px;

  & .ui-spinner {
    display: block;
    min-height: 100%;
  }
}
.howmanyitems input {
  display: inline-block;
  width: 52px;
  min-width: 52px;
  height: 37px;
  line-height: 35px;
  padding: 0;
  margin: 0;
  text-align: center;
  vertical-align: top;
  border: 1px solid #f3f3f3;
  color: #545454;
  outline: none;
}
.howmanyitems .ui-spinner-button {
  display: block;
  width: 27px;
  height: 37px;
  position: absolute;
  left: 1px;
  top: 0;
  box-shadow: inset 0 0 0 1px #f3f3f3;
  background-color: #fff;
  cursor: pointer;
}
.howmanyitems .ui-spinner-button.ui-spinner-up {
  right: 1px;
  left: auto;
}
.howmanyitems .ui-spinner-button.ui-spinner-up:before,
.howmanyitems .ui-spinner-button.ui-spinner-up:after {
  content: "";
  display: inline-block;
  position: absolute;
  right: 9px;
  top: 18px;
  background: #000;
  width: 9px;
  height: 1px;
}
.howmanyitems .ui-spinner-button.ui-spinner-up:after {
  width: 1px;
  height: 9px;
  top: 14px;
  right: 13px;
}

.howmanyitems .ui-spinner-button.ui-spinner-down:before,
.howmanyitems .ui-spinner-button.ui-spinner-down:after {
  content: "";
  display: inline-block;
  position: absolute;
  right: 9px;
  top: 18px;
  background: #000;
  width: 9px;
  height: 1px;
}
.howmanyitems .ui-button-text {
  display: none;
}
