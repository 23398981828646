.brands {
  &__alphabet {
    position: relative;
    height: 50px;
    display: flex;
    justify-content: center;
    /*&:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background: #eee;
    }*/
  }
  &__small-letter {
    line-height: 50px;
    padding: 0 12px;
    text-transform: uppercase;
  }
  &__row {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;

    /*&:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background: #eee;
    }*/
  }
  &__big-letter {
    font-size: 24px;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 12px;
  }
  &__column {
    padding-top: 25px;
    padding-bottom: 32px;
  }
  &__link {
    display: block;
    margin-bottom: 11px;
  }
  &__dropdown-link {
    padding: 5px 0 5px 15px;
    line-height: 1.5;
    display: block;
    text-transform: uppercase;
  }
}

/*:root:root:root .brands__dropdown {
  margin: 0 0 50px;
  padding:0;
  .trigger {
    box-shadow: inset 0 0 0 1px #e8e8e8;
  }
}*/


/* –––––––––––––––––––––––––––– */
/* ––––––– SMALL DESKTOP –––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 1279px) {

  .brands {
    &__column {
      padding-top: 34px;
      padding-bottom: 23px;
    }
  }

}



/* –––––––––––––––––––––––––––– */
/* –––––––––– TABLET –––––––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 1023px) {


}



/* –––––––––––––––––––––––––––– */
/* –––––––––– MOBILE –––––––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 767px) {

  .brands {
    /*&__dropdown {
      margin: 0 0 35px 0;
      max-width: 100%;
      min-width: 100%;
    }*/
    &__column {
      padding-top: 8px;
      padding-bottom: 19px;
    }
  }

  :root .brands__column {
    /*&:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background: #eee;
    }*/
  }
}
