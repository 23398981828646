/*** sticky stuff ***/
.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;

  &:before,
  &:after {
    content: '';
    display: table;
  }
}

.sticky-friend {

  &__wrapper {
    display: flex;
    align-items: stretch;
  }
}

@media (max-width: 1023px) {
  .sticky-friend {

    &__wrapper {
      display: block;
      align-items: inherit;
    }
  }
}
