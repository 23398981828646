.news-complex {
  &__wrapper {
    width: 1174px;
    height: 474px;
    margin: 0 auto;
    display: flex;
    overflow: hidden;
    position: relative;
    z-index: 1;
  }
  &--left {
    width: 686px;
    display: flex;
  }
  &--right {
    width: 488px;
  }
  &__link {
    display: block;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transition: background .55s;
    }
    &:hover {
      &:after {
        background: rgba(0,0,0,.5);
      }
    }
  }
  &__item--big {
    width: 392px;
    overflow: hidden;
    .news-complex {
      &__link {
        height: 474px;
      }
      &__image {
        height: 100%;
        width: auto;
        max-width: none;
      }
    }
  }
  &__items--small {
    width: 294px;
    display: flex;
    flex-direction: column;
  }
  &__item--small {
    width: 100%;
    height: 237px;
    overflow: hidden;
  }

  &__image {
    display: block;
    width: 100%;
    height: auto;
  }

  &__heading {
    opacity: 0;
    padding: 18px 0;
    line-height: 24px;
    font-size: 14px;
    color: #fff;
    letter-spacing: .1em;
    text-transform: uppercase;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    transition: opacity .75s;
    z-index: 1;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      width: 25px;
      height: 1px;
      left: 50%;
      margin-left: -13px;
      background: #fff;
    }
  }
  &__link:hover {
    .news-complex__heading {
      opacity: 1;
    }
  }
}

@media (max-width: 767px) {
  .news-complex {
    &__wrapper {
      width: 100%;
      height: auto;
      flex-direction: column;
    }
    &--left,
    &--right {
      width: 100%;
      flex-direction: column;
    }
    &__item {
      &--big {
        width: 100%;
        height: 297px;
        position: relative;
        .news-complex {
          &__image {
           height: auto;
           width: 100%;
         }
       }
      }
    }
    &__items {
      &--small {
        width: 100%;
        height: 594px;
        position: relative;
      }
    }
    &__item {
      &--small {
        width: 100%;
        height: 50%;
        position: relative;
      }
    }
    &__image {
      min-height: 100%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
    }
    &__link {
      position: static;
    }
    &__heading {
      max-width: 300px;
      display: inline-block;
      text-align: right;
      position: absolute;
      top: auto;
      left: auto;
      right: 0;
      bottom: 0;
      color: #fff;
      font-size: 25px;
      line-height: 45px;
      letter-spacing: .1em;
      text-transform: uppercase;
      padding: 26px 32px;
      transition: opacity .45s ease;
      transform: translate(0);
      &:after {
        display: none;
      }
    }
  }
}
