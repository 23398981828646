.account-order {
  &__heading {
    margin: 10px auto 13px;
  }

  & .account-menu {
    margin-top: 12px;
  }

  &__btns {
    margin-top: 33px;
  }
  &__cancel {
    font-size: 11px;
  }
}


/* –––––––––––––––––––––––––––– */
/* ––––––– SMALL DESKTOP –––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 1279px) {

  .account-order {
    &__heading {
      margin-top: 30px;
    }
  }

}


/* –––––––––––––––––––––––––––– */
/* –––––––––– TABLET –––––––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 1023px) {

  .account-order {

    & .account-menu {
      margin-top: 10px;
    }
    & .cart {
      margin-top: 112px;
    }
    & .cart-item {
      display: flex;
    }
    &__heading {
      position: absolute;
      top: 83px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

}


/* –––––––––––––––––––––––––––– */
/* –––––––––– MOBILE –––––––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 767px) {

  .account-order {
    margin-bottom: 50px;
    
    &__heading {
      font-size: 24px;
      font-size: 24px;
      top: 188px;
      white-space: normal;
      line-height: 29px;
    }

    & .account-menu {
      margin-top: -3px;
    }
    & .cart {
      margin-top: 150px;
    }
    & .cart-row {
      padding-bottom: 47px;
    }
    & .cart-item {
      display: block;
    }
  }

}
