.slider-products {
  margin: 0;

  & .slick-list {
    margin-left: -13px;
    margin-right: -13px;
  }

  &__slide {
    margin: 0 13px;
  }

  & .slick-arrow {
    position: absolute;
    top: -73px;
  }
  & .slick-prev {
    right: 20px;
  }
  & .slick-next {
    right: -10px;
  }


  /***** latent / main page *****/
  &--latent {
    margin-left: -26px;
    margin-right: -26px;
  }
}

/* –––––––––––––––––––––––––––– */
/* –––––– SMALL DESKTOP ––––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 1279px) {

  .slider-products {
    padding-top: 7px;

    &--latent {
      padding-top: 0;
    }
  }

}



/* –––––––––––––––––––––––––––– */
/* –––––––––– TABLET –––––––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 1023px) {

  .slider-products--latent {
    /***** latent / main page *****/
    .slider-products__slide {
      &:nth-child(7),
      &:nth-child(8) {
        display: none;
      }
    }

    &.slick-initialized {
      margin: 0;

      .slider-products__slide {

        &:nth-child(7),
        &:nth-child(8) {
          display: block;
        }

        & .col-sm-4 {
          width: 100%;
          padding: 0;
        }
      }
    }
  }

}

/* –––––––––––––––––––––––––––– */
/* –––––––––– MOBILE –––––––––– */
/* –––––––––––––––––––––––––––– */
@media(max-width: 767px) {

  :root .slider-products {
    width: 100%;
    margin: 0 auto 17px;
    padding-top: 0;
    padding-bottom: 111px;
    margin-bottom: -28px;
    margin-top: -4px;
    position: relative;

    &__slide {
      padding-bottom: 1px;
    }
    & .slick-arrow {
      height: 80px;
      width: 100px;
      bottom: 30px;
      top: auto;
      background-size: 12px 20px;
    }
    & .slick-prev {
      right: calc(50% + 13px);
      background-position: center right;
    }
    & .slick-next {
      left: calc(50% + 13px);
      background-position: center left;
    }

    /***** latent / main page *****/
    &--latent {
      margin-left: -26px;
      margin-right: -26px;
      padding-bottom: 85px;
      width: auto;

      & .slick-arrow {
        bottom: 30px;
      }

      .slider-products__slide {
        &:nth-child(5),
        &:nth-child(6) {
          display: none;
        }
      }
      &.slick-initialized {
        margin-left: 0;
        margin-right: 0;
        width: 100%;

        .slider-products__slide {

          &:nth-child(5),
          &:nth-child(6) {
            display: block;
          }
          & .col-xs-6 {
            width: 100%;
            padding: 0;
          }
        }
      }
    }/* end latent */
  }/* end product slider */

  :root .xs-bottom-border {
    .slider-products {
      margin-bottom: 11px;

      &:before {
        display: block;
        content: '';
        position: absolute;
        left: -20px;
        right: -20px;
        height: 1px;
        background: #f3f3f3;
      }
      &:before {
        bottom: 0;
      }
    }
  }
}
