.accordion {
  border-top: 1px solid #f3f3f3;
  &__item {
    position: relative;
    padding: 20px 10px;
    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left:0;
      height: 1px;
      width: 100%;
      background: #f3f3f3;
    }
  }
  &__title {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-transform: uppercase;
    letter-spacing: .1em;
    font-weight: 600;

    &:after {
      content: '';
      display: block;
      background: svg('accordion');
      flex: none;
      height: 6px;
      width: 10px;
      margin: 7px;
      align-self: flex-start;
    }
  }
  &__content {
    display: none;
    margin-top: 10px;
    padding-right: 32px;
    *:first-child {
      margin-top: 0;
    }
    *:last-child {
      margin-bottom: 0;
    }
  }
  &__item--opened &__title {
    color: #ff7600;
    &:after {
      transform: rotate(180deg);
      background: svg('accordion', '[fill]: #ff7600');
    }
  }
}

@media (max-width: 767px) {
  .accordion {
    &__item {
      padding: 20px 30px;
    }
  }
}
