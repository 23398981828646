.slider-last-viewed {
  margin: 0 0 1px;
  padding-top: 5px;

  & .slick-list {
    box-shadow: inset -1px 0 0 0 #ededed, inset 1px 0 0 0 #ededed;
  }

  &__slide {
    width: 180px;
    padding: 0 13px;
    box-shadow: inset -1px 0 0 0 #ededed, inset 0 1px 0 0 #ededed, inset 0 -1px 0 0 #ededed;
  }
  & .slick-arrow {
    position: absolute;
    top: -50px;
  }
  & .slick-prev {
    right: 35px;
  }
  & .slick-next {
    right: 0;
  }
}

@media(max-width: 767px) {
  .slider-last-viewed {
    width: 100%;
    margin: 0 auto 100px;
    padding-top: 0;

    & .slick-list {
      /*border-right: 1px solid #fff;*/
    }

    &__slide {
      height: 389px;
      padding: 0;
    }
    & .slick-arrow {
      height: 100px;
      width: 100px;
      bottom: -100px;
      top: auto;
    }
    & .slick-prev {
      right: calc(50% + 13px);
      background-position: center right;
    }
    & .slick-next {
      left: calc(50% + 13px);
      background-position: center left;
    }

    .product {

      &__image {
        padding-top: 40px;
      }
    }
  }
}
