.msg {
  margin-top: 110px;
  margin-bottom: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    font-size: 24px;
    text-align: center;
    margin-bottom: 15px;
  }
  &__desc {
    margin-bottom: 25px;
    text-align: center;
    color: #434a54;
  }
  &__btn {
    width: 275px;
  }
  &__title + &__btn {
    margin-top: 12px;
  }

  /***** page 404 *****/
  /*&__404 {
    font-size: 180px;
    line-height: 1;
    font-weight: 600;
    margin-top: -20px;
    margin-bottom: 20px;
  }*/

  /***** icons *****/
  &-icon {
    display: block;
    width: 170px;
    height: 170px;
    margin-bottom: 18px;

    &__success-order {
      background: svg('msg__success-order') no-repeat;
    }

    &__error-payment {
      background: svg('msg__error-payment') no-repeat;
    }
    &__success-payment {
      display: block;
      background: svg('msg__success-payment') no-repeat;
    }

    &__empty-cart,
    &__empty-catalog {
      background: svg('msg__error-catalog') no-repeat;
    }

    &__404,
    &__500 {
      background-size: cover;
      background-repeat: no-repeat;
      margin-bottom: 43px;
      margin-left: -20px;
    }
    &__404 {
      width: 432px;
      height: 198px;
      background-image: url(../img/404.png);
    }
    &__500 {
      width: 438px;
      height: 205px;
      background-image: url(../img/500.png);
    }

    &__no-orders {
      background: svg('msg__error-orders-empty') no-repeat;
    }
    &__no-favorites {
      background: svg('msg__error-fav-list') no-repeat;
    }

    &__subscribe {
      width: 172px;
      height: 172px;
      background: svg('msg__subscribe') no-repeat;
      margin: 40px auto;
      & + .popup__heading {
        line-height: 29px;
      }
    }

  }

  /* empty catalog */
  &__empty-catalog {
    .msg {
      margin-top: 25px;

      &-icon {
        margin-bottom: 22px;
      }
      &__btn {
        display: none;
      }
    }
  }

  /* empty orders & favorites */
  &__empty-orders,
  &__empty-favorites {
    .msg {
      margin-top: 106px;

      &-icon {
        margin-bottom: 24px;
      }
    }
  }

  /* 404 + 500 page */
  &__wrapper-404,
  &__wrapper-500 {
    .msg {
      margin-top: 110px;
    }
  }

}


/* –––––––––––––––––––––––––––– */
/* ––––––– SMALL DESKTOP –––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 1279px) {

  .msg {
    margin-top: 95px;
    margin-bottom: 40px;

    &__wrapper-404,
    &__wrapper-500 {
      .msg {
        margin-top: 140px;
      }
    }

    /* empty orders */
    &__empty-orders,
    &__empty-favorites {
      .msg {
        margin-top: 80px;
      }
    }
  }

}

/* –––––––––––––––––––––––––––– */
/* –––––––––– TABLET –––––––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 1023px) {

  .msg {
    margin-top: 115px;
    margin-bottom: 105px;

    &__empty-catalog {
      .msg {
        margin-top: 40px;
      }
    }

    &__empty-orders,
    &__empty-favorites {
      .msg {
        margin-top: 68px;
      }
    }

    &__wrapper-404,
    &__wrapper-500 {
      .msg {
        margin-top: 120px;
      }
    }
  }

}



/* –––––––––––––––––––––––––––– */
/* –––––––––– MOBILE –––––––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 767px) {

  .msg {
    margin-top: 135px;
    margin-bottom: 90px;

    &__title {
      margin-top: 15px;
      margin-bottom: 20px;
      line-height: 30px;
    }
    /*&__404 {
      font-size: 160px;
    }*/

    &__empty-catalog {
      .msg {
        margin-top: 72px;
      }
    }

    &__empty-orders,
    &__empty-favorites {
      .msg {
        margin-top: 58px;
      }
    }

    &__wrapper-404,
    &__wrapper-500 {
      .msg {
        margin-top: 152px;

        &-icon__404 {
          width: 280px;
          height: 128px;
          margin-left: 0;
        }
        &-icon__500 {
          width: 282px;
          height: 133px;
          margin-left: 0;
        }
      }
    }

  }

}
