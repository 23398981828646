.tab-panel {
  display: flex;
  box-shadow: inset 0 -1px 0 0 #f3f3f3;

  &__tab {
    display: block;
    padding: 0 24px;
    margin-right: 5px;
    text-align: center;
    background: none;
    outline: none;
    line-height: 38px;
    text-decoration: none;
    color: #000;
    border-top: 1px solid #eee;
    border-left: 1px solid #eee;
    border-right: 1px solid #eee;
    box-shadow: 0 1px 0 0 #fff;
    transition: border-color .45s,
    background .45s,
    box-shadow .45s,
    color .35s;

    & span {
      margin-left: 5px;
    }

    &.current {
      color: #ff7600;
      background: #fff;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        top: -1px;
        left: -1px;
        right: -1px;
        height: 2px;
        background: #ff7600;
      }
    }
  }

  /*** STYLED ***/
  &--styled {
    .tab-panel {
      &__tab {
        font-size: 12px;
        letter-spacing: .1em;
        line-height: 40px;
        border: 0;
        box-shadow: none;
        padding: 0;
        margin-right: 43px;
        text-transform: uppercase;

        &.current {
          background: transparent;

          &:after {
            top: auto;
            bottom: 0;
          }
        }
      }
    }

    & ~ .tab_content {

      p {
        line-height: 22px;
      }
    }
  }

}
.tab_content {
  position: relative;
  &__item {
    visibility: visible;
    opacity: 1;

    &.hidden {
    display: block !important;
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;
    opacity: 0;
    }
  }
}
