.noUi-origin {
	/*border-radius: 2px;*/
}
.noUi-target {
	/*border-radius: 4px;*/
	/*border: 1px solid #D3D3D3;*/
	/*box-shadow: inset 0 1px 1px #F0F0F0, 0 3px 6px -5px #BBB;*/
}
.noUi-target.noUi-connect {
	/*box-shadow: inset 0 0 3px rgba(51,51,51,0.45), 0 3px 6px -5px #BBB;*/
}

/*** main line bg ***/
.noUi-background {
	background: #f3f3f3;
}

/*** circles ***/
.noUi-handle {
	border-radius: 50%;
	background: #ff7600;
}
.noUi-horizontal .noUi-handle {
	width: 18px;
	height: 18px;
	top: -9px;

  &-lower {
    left: 0;
  }
  &-upper {
    left: -18px;
  }
}

/*** line between circles ***/
.noUi-connect {
  background: #ff7600;
}

/* Handle stripes;
 */
/*.noUi-handle:before,*/
/*.noUi-handle:after {*/
	/*content: "";*/
	/*display: block;*/
	/*position: absolute;*/
	/*height: 14px;*/
	/*width: 1px;*/
	/*background: #E8E7E6;*/
	/*left: 14px;*/
	/*top: 6px;*/
/*}*/
/*.noUi-handle:after {*/
	/*left: 17px;*/
/*}*/
/*.noUi-vertical .noUi-handle:before,*/
/*.noUi-vertical .noUi-handle:after {*/
	/*width: 14px;*/
	/*height: 1px;*/
	/*left: 6px;*/
	/*top: 14px;*/
/*}*/
/*.noUi-vertical .noUi-handle:after {*/
	/*top: 17px;*/
/*}*/
