.sitemap {
  &__column {
    padding-top: 5px;
    padding-bottom: 20px;
  }
  &__title {
    display: block;
    min-height: 30px;
    margin: 13px 0 22px 0;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: .1em;
    font-weight: 600;
  }
  &__list {
    padding-right: 30px;
  }
  &__item {
    padding-bottom: 1px;
    margin: 20px 0;
  }
  &__link {
    line-height: 16px;
    display: inline-block;
  }
}

/* –––––––––––––––––––––––––––– */
/* ––––––– SMALL DESKTOP –––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 1279px) {

  .sitemap {
    &__column {
      padding-top: 25px;
    }
    &__list {
      padding-right: 45px;
    }
  }

}

/* –––––––––––––––––––––––––––– */
/* –––––––––– TABLET –––––––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 1023px) {

  .sitemap {
    &__column {
      padding-top: 20px;
      padding-bottom: 5px;
    }
  }

}

/* –––––––––––––––––––––––––––– */
/* –––––––––– MOBILE –––––––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 767px) {

  .sitemap {
    &__column {
      padding-top: 0;
      padding-bottom: 0;

      &:first-child {
        margin-top: -57px;
      }
    }
    &__title {
      margin-top: 73px;
    }
  }

}
