.shop {

  &__slider {
    margin-top: 10px;
    overflow: hidden;
  }
  &__text {
    text-align: center;
    font-size: 13px;
    line-height: 20px;
    color: #545454;
    padding: 0 10px;
    margin-bottom: 50px;
  }

  &__contacts {
    border-top: 1px solid #f3f3f3;
    padding-top: 35px;
    padding-bottom: 15px;

    .shops__item {
      &-info {
        justify-content: flex-start;
        flex-direction: row;
        padding: 0;
      }
      &-icon {
        width: 32px;
        min-width: 32px;
        margin-top: 2px;
      }
      &-text {
        text-decoration: none;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }

}

/* –––––––––––––––––––––––––––– */
/* –––––––– BIG DESKTOP ––––––– */
/* –––––––––––––––––––––––––––– */
@media (min-width: 1280px) {

  .shop {
    &__wrapper {
      padding-top: 50px;
    }
    &__slider {
      height: 595px;
    }
    &__contacts {
      .shops__item {
        &-address {
          width: 50%;
          padding-right: 10px;
        }
        &-phone {
          width: 25%;
          padding-left: 10px;
        }
        &-clock {
          width: 25%;
          padding-left: 18px;
        }
      }
    }
    &__map {
      margin-bottom: 55px;
    }
  }

}

/* –––––––––––––––––––––––––––– */
/* ––––––– SMALL DESKTOP –––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 1279px) {

  .shop {
    &__wrapper {
      padding-top: 55px;
    }
    &__slider {
      height: 578px;
    }
    &__contacts {
      .shops__item {
        &-address,
        &-clock,
        &-phone {
          width: 33.3333%;
        }
        &-address {
          padding-right: 40px;
        }
        &-phone {
          padding-left: 60px;
        }
        &-clock {
          padding-left: 45px;
        }
      }
    }
    &__map {
      margin-bottom: 25px;
    }
  }

}

/* –––––––––––––––––––––––––––– */
/* –––––––––– TABLET –––––––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 1023px) {

  .shop {
    &__wrapper {
      padding-top: 68px;
    }
    &__slider {
      height: 430px;
    }
    &__contacts {
      .shops__item {
        &-address {
          padding-right: 0;
        }
        &-phone {
          padding-left: 67px;
        }
        &-clock {
          padding-left: 78px;
        }
      }
    }
  }

}


/* –––––––––––––––––––––––––––– */
/* –––––––––– MOBILE –––––––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 767px) {

  .shop {
    &__slider {
      margin-top: -4px;
      height: 540px;
      margin-left: -20px;
      margin-right: -20px;
    }
    &__wrapper {
      padding-top: 60px;
    }
    &__contacts {
      padding-top: 47px;
      padding-bottom: 42px;

      .shops__item {
        &-info {
          flex-direction: column;
        }
        &-address,
        &-clock,
        &-phone {
          width: 100%;
          padding: 0;
        }
        &-address {
          margin-bottom: 29px;
        }
        &-phone {
          margin-bottom: 24px;
        }
      }
    }
    &__map {
      margin-bottom: 35px;
    }
  }

}
