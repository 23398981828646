.prod_filter {
    position: relative;
}
.filters_block {
    padding: 0;
    background-color: transparent;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    /*margin: 0 1px 0 0;*/
    /*box-shadow: 1px 0 0 0 #e8e8e8;*/
}
/*.filter_item {
    min-width: 195px;
    max-width: 20%;
    height: 40px;
    position: relative;
    margin: 0 0 1px -1px;

    &--sort {
    margin: 0;
    flex: none;
    width: 179px;
    height: 25px;

        &:after {
        position: absolute;
        right: 0;
        top: 0;
        content: '';
        display: block;
        width: 1px;
        height: 100%;
        background: #ededed;
        }
    }
    &--clear {
      font-size: 11px;
      line-height: 43px;
      text-transform: uppercase;
      font-weight: 600;
      margin: 0 10px;
      opacity: .75;
      & .icon-cart-cross {
        margin: -2px 5px 0 0;
      }
    }
}*/
/*.filter_item .trigger {
    width: auto;
    height: 40px;
    position: relative;
    padding: 0 40px 0 20px;
    background: #f7f7f7;
    box-shadow: inset 0 0 0 1px #e8e8e8;
    cursor: pointer;
    z-index: 2;
}*/
/*.filter_item--sort .trigger {
    height: 52px;
}*/
/*.filter_item .trigger:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  margin-top: -2px;
  right: 15px;
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 5px solid #3f3f3f;
  border-bottom: 0 solid #3f3f3f;
}*/
/*.filter_item .trigger:after {
    content: '';
    display: block;
    height: 5px;
    width: 8px;
    position: absolute;
    top: 50%;
    margin-top: -1px;
    right: 20px;
    background: svg('trigger');
    flex: none;
    align-self: flex-start;
}
.filter_item.active .trigger:after {
    transform: rotate(180deg);
}
.filter_item.current .trigger:after {
    display: none;
}*/

/*.filter-clear {
    display: none;
    background-image: svg('cross');
    position: absolute;
    top: 2px;
    right: 6px;
    width: 35px;
    height: 35px;
    z-index: 2;
    background-color: transparent;
    border: none;
    outline: none;
    background-size: 8px 8px;
    background-position: center;
    background-repeat: no-repeat;
}

.filter_item.current .filter-clear {
    display: block;
}*/

/*.filter_item .f_value {
    font-size: 11px;
    line-height: 43px;
    color: #a1a1a1;
    letter-spacing: .1em;
    text-transform: uppercase;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}
.filter_item--sort .f_value {
    line-height: 52px;
    color: #999999;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}*/
/*.filter_item .dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 100%;
    margin-top: -1px;
    background: #f7f7f7;
    box-shadow: inset 0 0 0 1px #e8e8e8;
    z-index: 3;
}*/

/*.filter_item.current .trigger {
    background: #eeeeee;

& .f_value {
      color: #000;
      text-transform: none;
      letter-spacing: 0;
  }
}*/

/*** drops ***/


.dropdown {
    display: block;
    min-width: 100%;
    padding: 0;
    overflow: hidden;
    height: 0;
}
.js_hasdrop.active .dropdown {
    overflow: visible;
}
.drop_content {
    display: block;
}
.dropdown .check_list li + li {
    margin-top: 15px;
}
.quick_text .dropdown .check_list .current {
    font-weight: 600;
}

ul.check_list {
    padding: 19px 8px 4px 14px;
}

/* price range */
.price_range_wrapper {
  margin: 0;
  font-size: 12px;
  letter-spacing: normal;
}
.price_range_wrapper .price_fields {
  margin: 25px 0 0 0;
  line-height: 40px;
  display: flex;
  justify-content: space-between;
}

.price_item {
  display: inline-block;
}

/* range slider inputs */
.price-input.form-input {
  width: 77px;
  height: 37px;
  line-height: 28px;
  padding: 0 4px;
  text-align: center;
  margin-right: 8px;
  margin-left: 8px;
  &:first-child {
    margin-left: 0;
  }
}

/* ––––––––––––––––––––––––––––––––––––– */

@media (max-width: 767px) {
  /*.emx-catalog-sort {*/
    /*border-bottom: none;*/
    /*height: 0;*/
    /*margin-bottom: 0;*/
  /*}*/
  /*.emmix--sort_filter {*/
    /*position: absolute;*/
    /*!*top: -61px;*!*/
    /*width: calc(50% - 42px);*/
  /*}*/
  /*.emx-catalog-filters {*/
    /*padding: 30px;*/
  /*}*/
  /*.filters_block {
    display: none;
    flex-direction: column;
    position: absolute;
    z-index: 2;
    left: 0;
    right: 0;
    top: 60px;
    padding: 0;
    background-color: #f1f1f1;
    border-bottom: 1px solid #e8e8e8;
    border-top: 1px solid #e8e8e8;
    padding-top: 15px;

    .filter_item {
      flex: none;
      width: calc(100% - 60px);
      margin: 0 30px 15px;
      max-width: 100%;

      &--clear {
        text-align: center;
      }
      .trigger {
        box-shadow: inset 0 0 0 1px #e8e8e8;
      }
    }
    .filter_item:first-child {
      margin-top: 24px;
    }
  }
  :root .filter-toggle {
    margin-left: calc(50% + 15px);
    width: calc(50% - 15px);
    font-size: 14px;
    margin-top: -36px;
  }
  .filter-row {
    background-color: #f1f1f1;
    padding-top: 25px;
    padding-bottom: 15px;
    font-size: 14px;
  }
  .filter-submit {
    margin: 0 30px 10px;
    width: calc(100% - 60px);
    height: 44px;
    line-height: 44px;
  }
  .filter-close {
    display: block;
    margin: 0 30px;
    width: calc(100% - 60px);
    margin-bottom: 18px;
    text-align: center;
    cursor: pointer;
    border: none;
    background: transparent;
    font-size: 12px;
  }*/
  /*.emx-catalog-sort--collection {*/
    /*height: auto;*/
    /*background-color: #fafafa;*/
    /*padding: 25px 30px;*/
    /*margin-right: -30px;*/
    /*margin-left: -30px;*/
    /*text-align: center;*/
    /*margin-bottom: 48px;*/
    /*.emmix--sort_filter {*/
      /*position: relative;*/
      /*width: calc(360px - 2*30px);*/
      /*text-align: left;*/
      /*top: 0;*/
    /*}*/
  /*}*/
  /*.emx-catalog-sort:after {*/
    /*display: none;*/
  /*}*/
  /*.filter_item--sort:after {
    display: none;
  }
  .filter_item--sort {
    height: 36px;
    font-size: 14px;
    max-width: 50%;
    width: 50%;
    min-width: 0;

    .trigger {
      height: 34px;
    }
    .f_value {
      height: 34px;
      line-height: 34px;
    }
  }*/
  .price-input {
    width: 66px;
  }
}
