:root .slider-product-main {
  position: relative;
  overflow: hidden;
  height: 574px;

  & .slick-list,
  & .slick-track  {
    height: 100%;
    max-height: 100%;
  }

  &__slide {
    position: relative;
    height: 574px;

    &-image {
       display: block;
       height: auto;
       width: 100%;
       margin: 0 auto;
       min-width: 0;
       max-width: none;
       position: absolute;
       top: 50%;
       left: 50%;
       transform: translate(-50%,-50%);
    }
  }
  & .slick-dots {
    bottom: 16px;
    left: 50%;
    transform: translateX(-50%);
    margin-right: -50%;
    & li {
      background: #b2b2b2;
      &.slick-active {
        background: #000;
      }
    }
  }
}


/* –––––––––––––––––––––––––––– */
/* ––––––– SMALL DESKTOP –––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 1279px) {

  :root .slider-product-main {
    height: 460px;

    &__slide {
      height: 460px;
    }
  }

}

/* –––––––––––––––––––––––––––– */
/* –––––––––– TABLET –––––––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 1023px) {

  :root .slider-product-main {
    height: 340px;

    &__slide {
      height: 340px;
    }
  }

}


/* –––––––––––––––––––––––––––– */
/* –––––––––– MOBILE –––––––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 767px) {
  :root .slider-product-main {
    margin-top: 0;
    margin-left: -20px;
    margin-right: -20px;
    padding-top: 20px;
    padding-bottom: 1px;
    height: 320px;

    &__slide {
      height: 300px;

      &-image {
        width: auto;
        height: 100%;
      }
    }

    & .slick-dots {
      bottom: 13px;
    }
  }
}
