html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

body {
  color: #000;
  font-size: 13px;
  line-height: 1.5;
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex: 1 1 auto;
  position: relative;
  padding-bottom: 80px;
}
.fixed_head main {
  padding-top: 146px;
}
@media (max-width: 1023px) {
  main > .grid-container {
    padding-top: 72px;
  }

  main > .slider-jumbotron__wrapper {
    padding-top: 72px;
  }
  main .slider-jumbotron__wrapper ~ .grid-container {
    padding-top: 0;
  }
  .fixed_head main {
    padding-top: 0;
  }
}

@media (max-width: 1023px) {
  main {
    padding-bottom: 55px;
  }
}
@media (max-width: 767px) {
  main {
    padding-bottom: 30px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #000;
  text-transform: uppercase;
  letter-spacing: .1em;
  font-weight: 600;
}
h1 { font-size: 30px; }
h2 { font-size: 24px; }
h3 { font-size: 18px; }
h4 { font-size: 16px; }
h5 { font-size: 14px; }
h6 { font-size: 12px; }


p {
  margin-top: 20px;
  margin-bottom: 20px;
  color: #545454;

  &.p--styled {
    font-size: 16px;
    line-height: 26px;
    position: relative;
    margin-top: 45px;
    margin-bottom: 70px;

    &:after {
      content: '';
      position: absolute;
      bottom: -35px;
      left: 0;
      height: 1px;
      width: 35px;
      background: #000;
    }
  }
}

a {
  color: #000;
  text-decoration: underline;
  transition: color .45s;
  outline: none;

  &:hover {
    color: #ff7600;
  }
}

ul {
  list-style: none;
  padding-left: 25px;
  color: #545454;
  li {
    position: relative;
    & ~ & {
      margin-top: 10px;
    }
    &:before {
      content: '•';
      position: absolute;
      left: -25px;
      color: #ff7600;
    }
  }
}


.ul--reset {
  list-style: none;
  padding: 0;
  margin:0;
  li {
    &:before {
        display: none;
    }
    & ~ & {
      margin-top: 0;
    }
  }
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

img {
  max-width: 100%;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
  vertical-align: top;
}

button {
  padding: 0;
}

figure {
  margin: 25px 0 30px;
}

figcaption {
  color: #a8a8a8;
  font-style: italic;
  margin-top: 10px;
}

input, textarea, select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
  padding: 0;
}
