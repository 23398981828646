:root:root:root {
  #informers-container {
  	  position: fixed;
      bottom: 0;
      width: 100%;
      z-index:10000;
  }

  #informers-container .message {
      text-align: center;
      color: #fff;
      padding: 15px 30px;
      border-top: 1px solid #fff;
      line-height: 20px;
      white-space: normal;
      span {
          max-height: 43px;
          overflow: hidden;
          display: inline-block;
        /*display: inline-block;*/
        /*background-repeat: no-repeat;*/
        /*padding-left: 35px;*/
        /*background-size: 18px 18px;*/
        /*background-position: 0 2px;*/
        /*text-align: center;*/
      }
  }

  #informers-container .info {
  	background-color: #282826;
    & span {
      /*background-image: svg('msg-success', '[fill]: #fff')*/
    }
  }

  #informers-container .error  {
  	background-color: #282826;
    & span {
      /*background-image: svg('msg-error', '[fill]: #fff')*/
    }
  }

  #informers-container .warning {
  	background-color: #282826;
    & span {
      /*background-image: svg('msg-error', '[fill]: #fff')*/
    }
  }
  #informers-container .success {
  	background-color: #282826;
    & span {
      /*background-image: svg('msg-success', '[fill]: #fff')*/
    }
  }
}
