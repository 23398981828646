.breadcrumbs {
  position: relative;
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  padding-top: 20px;
  margin-bottom: 40px;

  &__item {
    display: flex;
    align-items: flex-end;

    &:after {
      display: block;
      content: '/';
      margin: 0 8px;
      align-self: center;
      color: #a4a4a4;
      font-size: 11px;
      line-height: 12px;
    }
    &:last-child:after {
      display: none;
    }
  }
  &__link {
    font-size: 11px;
    line-height: 13px;
    color: #a4a4a4;
    white-space: nowrap;

    &--current.link {
      box-shadow: none;
    }
  }
  a&__link {
    color: #a4a4a4;
    box-shadow: 0 1px 0 0 rgba(0,0,0,.25);
  }
}
