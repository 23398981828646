.checkout {
  font-size: 13px;
  margin-bottom: 177px;

  &__primary-title {
    margin-top: 26px;
    margin-bottom: 26px;
  }

  &__step-header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 23px;
  }
  &__step-heading {
    margin: 0;
    line-height: 1;
    white-space: nowrap;
  }
  &__substep-heading {
    font-weight: 600;
    margin: 0;
    line-height: 1;
    margin-top: 40px;
  }
  &__step {
    position: relative;
    padding: 38px 10px 41px;
    &:after{
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 1px;
      background: #eee;
    }
    &:first-child:before{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 1px;
      background: #eee;
    }
  }
  &__step-content {
    & .checkbox {
      margin-top: 10px;
      margin-bottom: 18px;
    }
    & .sign-in-actions {
      margin-top: 0;
      margin-bottom: 0;
      & .link {
        margin-top: -10px;
      }
    }
    & .tab-panel {
      padding-top: 3px;
      margin-bottom: 29px;
    }
    & .form-input--textarea {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
  &__substep {
    position: relative;
    &:after{
      position: absolute;
      left: 0;
      bottom: 0;
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background: #eee;
    }
    &--no-border {
      &:after{
        display: none;
      }
    }
    &--inputs {
      padding-bottom: 30px;
      & .checkout__substep-heading {
        margin-bottom: 19px;
      }
      & .address-select {
        margin-top: 14px;
      }
      & .address-actions {
        margin-top: 11px;
      }
    }
    &--radios {
      padding-bottom: 22px;
      & .checkout__substep-heading {
        margin-bottom: 31px;
        margin-top: 42px;
      }
      & .radio {
        margin-bottom: 18px;
      }
    }
  }

  &__auth-toggle-wrapper {
    margin-bottom: 29px;
    line-height: 14px;

    & .link {
      margin-left: 5px;
    }
  }
  &__phone {
    font-weight: bold;
    &--md {
      text-align: right;
    }
  }
  &__step-summary {
    display: none;
  }
  &__step-edit {
    display: none;
    line-height: 15px;
  }
  &__total {
    position: relative;
    padding: 32px 30px 30px;
    margin: 41px 0 30px;
    display: flex;
    justify-content: flex-end;
    &:before{
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background: #eee;
    }
    &:after{
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background: #eee;
    }
  }
  &__edit-trigger {
    position: absolute;
    display: inline-block;
    right: 35px;
    top: 3px;
    /*bottom: 0;*/
  }
  &__remove-trigger {
    display: inline-block;
    width: 25px;
    position: absolute;
    top: 3px;
    /*bottom: 0;*/
    right: -2px;
    cursor: pointer;
  }

  &__delivery-price {
    font-weight: 700;
    text-align: right;
    &-free {
      color: #ff7600;
    }
  }

  &__add-new-address {
    margin-top: 32px;
    margin-bottom: -3px;
  }

  &__edit-address {
    margin-top: 32px;
  }

  /*** cart items ***/
  &__order-summ {
    .order-details__summ {
      margin-top: -7px;
      padding-left: 48px;
    }
  }

  /*** check rules ***/
  &__rules-agreement-link {
    margin-left: 4px;
  }
}

.checkout__step--not-started {
  padding: 30px 10px 27px;
  .checkout__step-content {
    display: none;
  }
  .checkout__step-header {
    margin: 0;
  }
  .checkout__step-heading{
    font-family: inherit;
    font-size: 14px;
    color: #a8a8a8;
    text-transform: none;
    font-weight: normal;
    letter-spacing: 0;
  }
}

.checkout__step--completed {
  padding: 29px 10px 27px;
  .checkout__step-edit {
    display: block;
  }
  .checkout__step-content {
    display: none;
  }
  .checkout__step-header {
    margin-bottom: 11px;
  }
  .checkout__step-heading{
    font-family: inherit;
    font-size: 14px;
    padding-left: 2px;
    text-transform: none;
    font-weight: bold;
    letter-spacing: 0;
  }
  .checkout__step-summary {
    display: block;
  }
}


/* –––––––––––––––––––––––––––– */
/* –––––––– BIG DESKTOP ––––––– */
/* –––––––––––––––––––––––––––– */
@media (min-width: 1280px) {

}

/* –––––––––––––––––––––––––––– */
/* ––––––– SMALL DESKTOP –––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 1279px) {

  .checkout {
    &__primary-title {
      margin-top: 16px;
    }

    /*** cart items ***/
    &__order-summ {
      .order-details__summ {
        margin-top: -7px;
        padding-left: 48px;
        margin-top: -21px;
        padding-left: 15px;
      }
    }
  }

}

/* –––––––––––––––––––––––––––– */
/* –––––––––– TABLET –––––––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 1023px) {

  .checkout {
    margin-top: 13px;
  }

}


/* –––––––––––––––––––––––––––– */
/* –––––––––– MOBILE –––––––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 767px) {

  .checkout {
    margin-bottom: 60px;
    &__step {
      padding: 40px 20px;
      margin: 0 -20px;
    }
    &__substep {
      margin: 0 -20px;
      padding-left: 20px;
      padding-right: 20px;

      &--inputs {
        padding-bottom: 40px;

        .address-actions {
          margin-left: 20px;
        }
      }
    }
    &__delivery {
      &-radio.radio {
        margin-bottom: 6px;
      }
      &-days {
        padding-left: 32px;
        margin-bottom: 6px;
      }
      &-price {
        text-align: left;
        padding-left: 32px;
        margin-bottom: 20px;
      }
    }
    &__auth-toggle-wrapper {
      & span {
        margin-left: 2px;
      }
    }
    &__step-content {
      & .checkbox {
        margin-top: 20px;
      }
      & .sign-in-actions {
        & .checkbox {
          margin-top: 0;
        }
      }
    }

    &__total {
      margin-top: 0;
      padding-left: 13px;
    }
    .order-details__summ {
      margin-top: -9px;
    }
  }

}
