.promo-form {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  margin-left: -12px;

  &__wrapper {
    display: flex;
  }
  &__input {
    margin-right: -1px;
  }
  &__submit {
    height: 45px;
    line-height: 45px;
    width: 45px;
    min-width: 45px;
    border-color: #ececec;

    &-icon {
      width: 7px;
      height: 12px;
      fill: #9a9a9a;
      margin-top: -2px;
      margin-left: 2px;
    }
  }
  &__input:focus + &__submit {
    border-color: #cdcdcd;
  }
  &__message {

  }
}

/* –––––––––––––––––––––––––––– */
/* ––––––– SMALL DESKTOP –––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 1279px) {
  .promo-form {
    margin-top: 5px;
    margin-left: -23px;
  }
}

/* –––––––––––––––––––––––––––– */
/* –––––––––– TABLET –––––––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 1023px) {
  .promo-form {
    margin-left: -14px;
  }
}


/* –––––––––––––––––––––––––––– */
/* –––––––––– MOBILE –––––––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 767px) {

  .promo-form {
    margin-left: -13px;
    margin-right: -13px;
    margin-top: 0;
    padding-bottom: 40px;
    border-bottom: 1px solid #f3f3f3;
    width: auto;
  }
  
}
