::placeholder {
  color: #a4a4a4;
}

.form-input {
  width: 100%;
  height: 45px;
  line-height: 45px;
  font-size: 13px;
  padding: 1px 18px 0;
  outline: none;
  color: #545454;
  border: 1px solid #ececec;
  transition: border-color .45s, color .35s;
  
  &:focus,
  &:active {
    color: #545454;
    border-color: #cdcdcd;
  }
  &:disabled {
   background-color: #f2f1ef;
   color: #a4a4a4;
  }
  &--inline {
    width: auto;
    display: inline-block;
    vertical-align: top;
  }
  &--lg {
    height: 40px;
  }
  &--textarea {
    min-height: 102px;
    height: auto;
    line-height: 1.5;
    padding: 10px;
    margin-bottom: 15px;
  }
}

.form-row {
  width: 100%;
  margin-bottom: 10px;
  text-align: left;

  &--no-margin-bottom {
    margin-bottom: 0;
  }
  &__error,
  &__success {
    font-size: 11px;
    display: none;
    margin: 5px 0 1px;
    line-height: 11px;
    text-transform: lowercase;
  }
  &__error {
    color: #ff3a4d;
  }
  &__success {
    color: #63a474;
  }
}


.form-row.error {
  .form-input {
    border-color: #ff4444;
  }
  .form-row__error {
    display: block;
  }
}

.form-row.success {
  .form-input {
    border-color: #85da9b;
  }
  .form-row__success {
    display: block;
  }
}

@media (min-width: 768px) {
  .form-row--no-margin-bottom-md {
    margin-bottom: 0;
  }
}
