/* popups: DEFAULT STYLES */
.popup {
  min-width: 425px;
  max-width: 85vw;
  min-height: 285px;
  max-height: 85vh;
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: auto;
  transform: translate(-50%,-50%);
  margin-right: -50%;
  overflow: hidden;

  &__wrapper {
    background: rgba(78,78,78,.5);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 12;
  }

  &__close {
    width: 72px;
    height: 72px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    z-index: 1;

    &:after {
      display: block;
      content: '';
      background-image: svg('cross__popup','[fill]: #474747');
      background-size: 23px 23px;
      transition: background-image .45s;
      width: 23px;
      height: 23px;
    }

    &:hover:after {
      background-image: svg('cross__popup', '[fill]: #ff7600');
    }
  }

  &__inner {
    min-width: 275px;
    padding: 30px 20px;
  }
  &__heading {
     font-weight: 600;
     margin-top: 35px;
     margin-bottom: 27px;
     text-align: center;
  }
  &__body {

  }
  &__footer {

  }

  /*** map pick-up list ***/
  &__map-wrapper{
    height: 540px;
    width: 1084px;
    padding-left: 250px;
    margin: -30px -30px -40px;
    .gmnoprint{
      display: none;
    }
    img[src^="https://maps.gstatic.com/mapfiles"]{
      display:none !important;
    }
  }
  &__pickup-list{
    width: 250px;
    height: 540px;
    position: absolute;
    background: #fff;
    top: 0;
    left: 0;
    z-index: 10;
    &.customscroll{
      max-height: 540px;
    }
  }
  &__pickup-item{
    padding: 24px;
    font-size: 12px;
    border-bottom: 1px solid #ccc;
    transition: .3s background ease;
    cursor: pointer;
    &:hover{
      background: #ececec;
    }
    &.active{
      background: #ececec;
      cursor: default;
    }
  }
  &__pickup-txt{
    margin-bottom: 10px;
  }
  &__pickup-tel{
     font-family: "Proxima Nova Bold", sans-serif;
  }

  /*** customscroll ***/
  & .customscroll {
    max-height: 500px;
  }
  & .mCustomScrollbar &__inner {
    padding-right: 65px;
  }
}

/* –––––––––––––––––––––––––––– */
/* ––––––– SMALL DESKTOP –––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 1279px) {

}

/* –––––––––––––––––––––––––––– */
/* –––––––––– TABLET –––––––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 1023px) {

  .popup {
    &__map-wrapper {
      width: 100%;
      min-width: 720px
    }
  }

}

/* –––––––––––––––––––––––––––– */
/* –––––––––– MOBILE –––––––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 767px) {

  .popup {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin-right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translate(0);
    min-width: 100%;
    max-width: none;
    max-height: none;

    &__wrapper {
     background: #fff;
     z-index: 99;
    }
    &__inner {
      min-width: 100%;
    }

    &__pickup-list{
      display: none;
    }
    &__map-wrapper {
      padding-left: 0;
      height: auto;
      min-height: 100vh;
      min-width: 100vw;
      margin: 0 -20px;
      position: absolute;
      top: 0;
      bottom: 0;
    }

    & .customscroll {
      max-height: 100vh;
    }
    & .mCustomScrollbar &__inner {
      padding-right: 55px;
    }
  }

}
