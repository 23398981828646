/* main header : h1 */
.page-heading {
  display: block;
  color: #000;
  text-align: center;
  margin: 40px 0 35px;
  text-transform: uppercase;
  font-weight: 600;

  &--small {
    font-size: 24px;
    margin: 38px 0 34px;
    line-height: 30px;
  }

  &.link {
    letter-spacing: .1em;
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }
}

/* slider headers : h2 - h3 */
.slider-heading {
  text-align: center;
  margin: 30px 0;
  line-height: 36px;
}

/* –––––––––––––––––––––––––––– */
/* –––––– SMALL DESKTOP ––––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 1279px) {

  .page-heading {

    &--small {

    }
  }
}


/* –––––––––––––––––––––––––––– */
/* –––––––––– TABLET –––––––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 1023px) {

  .page-heading {

    &--small {

    }
  }
}

/* –––––––––––––––––––––––––––– */
/* –––––––––– MOBILE –––––––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width:767px) {
  .page-heading {
    line-height: 36px;
    margin: 43px 0 38px;
    &--small {
      line-height: 30px;
    }
  }
  .slider-heading {
    margin: 35px 0 30px;
  }
}
