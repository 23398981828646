/* checkbox & radio common styles */
.checkbox,
.radio {
  line-height: 20px;
  display: flex;
  font-size: 13px;
  margin-bottom: 11px;
  cursor: pointer;

  &__input {
    display: none;
  }
  &__icon {
    width: 20px;
    height: 20px;
    cursor: pointer;
    vertical-align: top;
    background-repeat: no-repeat;
    flex: none;
  }
  &__label {
    vertical-align: top;
    padding-left: 12px;
  }
  &--no-margin-bottom {
    margin-bottom: 0;
  }
}

/* checkbox styles */
.checkbox__icon {
  background-image: svg('checkbox');
}
.checkbox__input:disabled {
  & ~ .checkbox__icon {
    background-image: svg('checkbox--disabled');
    cursor: default;
  }
  & ~ .checkbox__label {
    cursor: default;
  }
}
.checkbox.error {
  .checkbox__icon {
    background-image: svg('checkbox--error');
  }
}
.checkbox__input:checked {
  & ~ .checkbox__icon {
    background-image: svg('checkbox--checked');
  }
}
.checkbox__input:checked:disabled {
  & ~ .checkbox__icon {
    background-image: svg('checkbox--checked-disabled');
    cursor: default;
  }
  & ~ .checkbox__label {
    cursor: default;
  }
}
.checkbox.error {
  .checkbox__input:checked {
    & ~ .checkbox__icon {
      background-image: svg('checkbox--checked-error');
    }
  }
}


/* radio styles */
.radio__icon {
  background-image: svg('radio');
}
.radio__input:disabled {
  & ~ .radio__icon {
    background-image: svg('radio--disabled');
    cursor: default;
  }
  & ~ .radio__label {
    cursor: default;
  }
}
.radio.error {
  .radio__icon {
    background-image: svg('radio--error');
  }
}
.radio__input:checked {
  & ~ .radio__icon {
    background-image: svg('radio--checked');
  }
}
.radio__input:checked:disabled {
  & ~ .radio__icon {
    background-image: svg('radio--checked-disabled');
    cursor: default;
  }
  & ~ .radio__label {
    cursor: default;
  }
}
.radio.error {
  .radio__input:checked {
    & ~ .radio__icon {
      background-image: svg('radio--checked-error');
    }
  }
}

/* hidden checkbox/radio list */
.radio__group,
.checkbox__group {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: flex-start;

  &--hidden-radio,
  &--hidden-checkbox {
    .radio__icon,
    .checkbox__icon {
      display: none;
    }
  }
}
