.account-orders {
  display: flex;
  flex-flow: column;
  margin-top: 20px;

  &__header {
    display: flex;
    flex-flow: row;
    border-bottom: 2px solid #f3f3f3;
    font-size: 13px;
    font-weight: 600;
    width: 100%;
  }

  &__item {
    position: relative;
    display: flex;
    flex-flow: row;
    text-decoration: none;
    width: 100%;

    &:after{
      content: '';
      display: table-row;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background: #eee;
    }
    &:hover {
      color: inherit;
      background-color: #fdfbf9;
    }

    & span {
      margin: 0 5px;
    }
  }
  &__header-cell {
    width: 25%;
    padding: 12px 12px 14px;
  }
  &__body-cell {
    width: 25%;
    padding: 30px 12px 22px;
    line-height: 30px;
  }
}

/* order status */
.order-status {
  display: inline-block;
  padding: 0 10px;
  line-height: 30px;
  font-size: 13px;
  color: #545454;

  &--new {
    background-color: #eaf3ff;
  }
  &--done {
    background-color: #e2ffe1;
  }
  &--canceled {
    background-color: #f3f3f3;
  }
  &--sent {
    background-color: #fff9d3;
  }
  &--not-paid {
    background-color: #ffeaea;
  }
}

/* –––––––––––––––––––––––––––– */
/* ––––––– SMALL DESKTOP –––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 1279px) {

  .account-orders {
    &__header-cell {
      width: 33.3333%;
    }
    &__body-cell {
      width: 33.3333%;
    }
  }

}


/* –––––––––––––––––––––––––––– */
/* –––––––––– TABLET –––––––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 1023px) {

  .account-orders {
    margin-top: 50px;

    &__header-cell {
      width: 25%;
    }
    &__body-cell {
      width: 25%;
    }
  }

}



/* –––––––––––––––––––––––––––– */
/* –––––––––– MOBILE –––––––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 767px) {
  .account-orders {
    margin-top: 40px;
    margin-bottom: 40px;

    &__header-cell {
      width: 50%;
    }
    &__body-cell {
      width: 50%;
    }
  }
}
