.contacts {
  padding: 27px 40px;
  position: relative;
  margin-bottom: 55px;
  border: 1px solid #eee;

  /*&:after{
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: #eee;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: #eee;
  }*/

  &__table {
    padding-top: 18px;
    margin-bottom: 60px;

    td {
      padding: 8px 0 9px;
      vertical-align: top;
      line-height: 16px;
      font-size: 13px;
      font-family: 'Open Sans', Arial, Helvetica, sans-serif;
      min-width: 130px;

      /* prevent phone-behaviour for Safari */
      /*pointer-events: none;*/
      & > a {
        text-decoration: none;
        color: inherit;
      }

      &:last-child {
        padding-left: 25px;
      }
    }
  }

  &__map {
    margin-bottom: -80px;
  }
}


/* –––––––––––––––––––––––––––– */
/* ––––––– SMALL DESKTOP –––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 1279px) {

  .contacts {
    &__table {
      padding-top: 15px;
      margin-bottom: 65px;
    }
  }

}

/* –––––––––––––––––––––––––––– */
/* –––––––––– TABLET –––––––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 1023px) {

  .contacts {
    &__table {
      padding-top: 13px;
      margin-bottom: 53px;
    }
  }

}

@media (max-width: 767px) {
  .contacts {
    /*padding: 0;
    margin-bottom: 140px;*/

    /*&:after,
    &:before {
      display: none;
    }*/

    &__table {
      padding-top: 0;
      margin-bottom: 0;
      margin-top: -4px;

      td {
        min-width: 135px;
        padding: 9px 0 8px;

        &:last-child {
          padding-left: 10px;
        }
      }

      .link {
        display: inline-block;
      }
    }
  }

  .btn-map {
    margin-top: 50px;
    margin-bottom: 25px;
  }

}
