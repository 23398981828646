/***** social stuff *****/
.social {
  &__icons {
    display: flex;
    justify-content: center;
  }

  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    margin: 0 8px;
  }
  &__icon {
    fill: #ccc;
    margin: auto;
    transition: fill .55s;

    &:hover {
      fill: #a8a8a8;
    }

    &--fb {
      width: 8px;
      height: 14px;
    }
    &--vk {
      width: 17px;
      height: 11px;
    }
    &--ig {
      width: 16px;
      height: 16px;
    }
    &--gplus {
      width: 15px;
      height: 14px;
    }
    &--mkrug {
      width: 16px;
      height: 14px;
    }
    &--ok {
      width: 9px;
      height: 14px;
    }
    &--tw {
      width: 16px;
      height: 13px;
    }
  }
}

@media (max-width: 767px) {

  .social {
    &__item {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 30px;
      margin: 0 8px;
    }
    &__icon {
      fill: #bfbfbf;
      &:hover {
        fill: #a8a8a8;
      }

      &--fb {
        width: 10px;
        height: 17px;
      }
      &--vk {
        width: 20px;
        height: 16px;
      }
      &--ig {
        width: 17px;
        height: 17px;
      }
    }
  }

}
