/*** table of speciification ****/
.spec {

  &__wrapper {
    padding-top: 20px;
  }

  &__row {
    position: relative;
    display: flex;
    align-items: center;
    padding: 11px 0 10px;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      height: 1px;
      margin-top: 1px;
      background: #f3f3f3;
    }
  }

  &__col {
    width: 35%;
    position: relative;
    padding-left: 28px;
    background: #fff;
    z-index: 1;

    &:first-child {
      padding-left: 0;
      padding-right: 25px;
      width: 65%;
      background: transparent;
    }

    &-heading {
      background: #fff;
      padding-right: 28px;
    }
  }

  &__all {
    margin-top: 16px;
  }
}

/* –––––––––––––––––––––––––––– */
/* –––––––––– TABLET –––––––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 1023px) {
  .spec {

    &__wrapper {
      padding-top: 21px;
    }
    &__col {
      width: 55%;

      &:first-child {
        width: 45%;
      }
    }
  }
}

/* –––––––––––––––––––––––––––– */
/* –––––––––– MOBILE –––––––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 767px) {

  .spec {
    &__row {
      &:before {
        display: none;
      }
    }
    &__col {
      padding-left: 0;
      width: 50%;
      &:first-child {
        width: 50%;
      }
    }
    &__all {
      font-size: 12px;
    }
  }

}
