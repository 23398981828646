.authorization-form {
  font-size: 13px;
  text-align: center;
  margin-bottom: 60px;
  margin-top: 15px;

  /*.form-row {
    margin-bottom: 14px;
  }*/

  .link--primary {
    margin-left: 5px;
    line-height: 16px;
  }

  .btn--primary {
    margin: 15px auto 17px;
    max-width: 374px;
  }

  .column {
    width: 308px;
  }

  .popup & {
    margin-bottom: 0;
  }
}

/* –––––––––––––––––––––––––––– */
/* –––––––––– TABLET –––––––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 1023px) {

  .authorization-form {
    margin-top: 10px;
  }

}


@media(max-width: 767px){
  .authorization-form {
    .column {
      width: 100%;
    }
    .btn--primary {
      margin-top: 20px;
    }
  }
}
