.price {
  &__current {
    white-space: nowrap;
  }
  &__old {
    color: #a8a8a8;
    text-decoration: line-through;
    margin-left: 0.7em;
    white-space: nowrap;
  }
}
