/*@font-face {
    font-family: 'Glober Regular';
    src: url('../fonts/glober-regular.woff2') format('woff2'),
         url('../fonts/glober-regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Glober Semibold';
    src: url('../fonts/glober-semibold.woff2') format('woff2'),
         url('../fonts/glober-semibold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}*/
