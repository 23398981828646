/******** FOOTER *******/
.page-footer {
  margin-top: -1px;
  position: relative;
  z-index: 1;
}
.footer_main {
  background-color: #fdfbf9;
}

/*** subscribe *****/
.footer_subscribe_block  {
  padding: 28px 0;
  border-bottom: 1px solid #ececec;
}

/*** site map list *****/
.footer-list {
  padding-top: 8px;
  padding-left: 0;

  &__title {
    display: block;
    min-height: 25px;
    margin: 11px 0 3px 0;
    font-size: 12px;
    line-height: 25px;
    letter-spacing: .1em;
    font-weight: 600;
  }

  &__item {
    padding-bottom: 1px;
    margin: 15px 0;
  }
  &__link {
    font-size: 12px;
  }
}

/***** additional info *****/
.footer_info {
  padding-top: 30px;
  padding-bottom: 38px;
  display: flex;
  justify-content: space-between;
}


/***** forum summary *****/
.forum {
  &__footer-list {
    padding-top: 7px;

    .forum {
      &__summary {
        margin-bottom: 13px;
        padding-top: 17px;

        &-title {
          display: inline-block;
          white-space: normal;
          line-height: 16px;
        }
        &-info {
          padding-top: 1px;
        }
      }
    }
  }

  &__link {
    display: inline-block;
    font-size: 12px;
    line-height: 15px;
    margin-top: 6px;
  }
}


/***** copyright + kodix stuff *****/
.footer_bottom {
  background-color: #fdfbf9;
  border-top: 1px solid #ececec;

  .simple_footer {
    display: flex;
    height: 62px;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;

    &_copyright {
      color: #434a54;
    }
    &_social {
      margin-left: -40px;
    }
    &_made {
      color: #545454;
      .kodix {
        margin-left: 3px;
        text-decoration: none;
        color: #545454;
        transition: box-shadow .45s;
        &:hover {
          box-shadow: inset 0 -1px 0 0 #545454;
        }
      }
    }
  }
}

/* –––––––––––––––––––––––––––– */
/* ––––––– SMALL DESKTOP –––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 1279px) {

  .forum {
    &__summary {
      &-title {
        line-height: 18px;
      }
      &-info {
        padding-top: 2px;
      }
    }
  }

}

/* –––––––––––––––––––––––––––– */
/* –––––––––– TABLET –––––––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 1023px) {
  .footer_info {
    padding-bottom: 45px;
  }
}

/* –––––––––––––––––––––––––––– */
/* –––––––––– MOBILE –––––––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 767px) {
  .footer_subscribe_block {
    border-bottom: 0;
    padding: 0;
  }
  .mobile-subscribe {
    &__area {
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: .1em;
      padding-top: 8px;
      cursor: pointer;
    }
  }
  .footer_bottom {
    & .simple_footer {
      height: 70px;
      justify-content: center;
    }
    & .simple_footer_social {
      margin-left: -2px;
      & .social__item {
        &:last-child {
          margin-left: 16px;
        }
      }
    }
  }

}
/******** /FOOTER *******/
