.forum {

  &__list {
    padding-left: 13px;
    padding-right: 13px;
    margin-bottom: 30px;
  }

  &-item {
    color: #545454;
    padding-top: 25px;
    padding-bottom: 30px;
    border-bottom: 1px solid #f3f3f3;

    /*** header row ***/
    &--header {
      min-height: 50px;
      padding-top: 0;
      padding-bottom: 0;
      display: flex;
      align-items: center;
      background-color: #fdfbf9;
      border-bottom: 2px solid #f3f3f3;
    }
    &__heading {
      font-size: 12px;
      color: #000;
      font-weight: 600;
      letter-spacing: .1em;
      text-transform: uppercase;
      margin-top: 4px;
    }

    /*** element row ***/
    &__title {
      display: block;
      font-size: 13px;
      font-weight: 700;
      margin-bottom: 4px;
    }
    &__desc {
      font-size: 12px;
      line-height: 15px;
    }

    /*** theme item ***/
    &--theme {
      padding-bottom: 32px;

      .forum-item {
        &__name {
          padding-top: 3px;
        }
      }

      .forum {
        &__summary-info {
          padding-top: 17px;
        }
        &-item__name &__summary-info {
          padding-top: 4px;
        }
      }
    }

  }/* end item */

  /*** last activity summary ***/
  &__summary {
    &-title {
      display: block;
      white-space: nowrap;
      font-size: 13px;
      line-height: 20px;
      color: #000;
      /*margin-bottom: 4px;*/
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &-info {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      font-size: 12px;
      font-style: italic;
      padding-top: 4px;
    }
    &-user {
      color: #000;
    }
    &-separator {
      margin: 0 7px 0 6px;
      color: #545454;
    }
    &-date {
      color: #545454;
    }
  }/* end summary */


  /*** themes ***/
  &__controls {
    &-btn {
      height: 45px;
      line-height: 45px;
    }
  }
  &__select {
    display: flex;
    align-items: center;

    &-label {
      white-space: nowrap;
      margin-right: 13px;
      min-width: 100px;
    }
  }

  &__pagination {
    margin-top: -11px;
    .catalog-sorting {
      &__pagination-display-items {
        padding-left: 0;
        margin-left: -8px;
      }
    }

    &--post {
      margin-bottom: 40px;
    }
  }

  /*** post ***/
  &__post-wrapper {
    padding-left: 13px;
    padding-right: 13px;
  }
  &-post {
    padding-top: 30px;
    padding-bottom: 45px;
    border-bottom: 1px solid #f3f3f3;

    &__avatar {
      height: 74px;
      background-size: cover;
      margin-left: -13px;
      margin-right: -9px;
      border-radius: 50%;
      overflow: hidden;
    }
    &__body {
      padding-left: 8px;
      position: relative;
    }
    &__author {
      font-size: 15px;
      line-height: 25px;
      font-weight: 600;
      padding-top: 3px;
    }
    &__text {
      padding-right: 25px;
      margin-top: 15px;
      margin-bottom: 19px;

      & p {
        line-height: 20px;
      }
    }
    &__date {
      margin-top: -2px;
      font-style: italic;
      font-size: 12px;
    }
    &__reply {
      display: inline-block;
      position: absolute;
      right: 10px;
      top: 5px;
    }
    &__answer{
       border-top: 1px solid #f3f3f3;
       padding-top: 10px;
       margin-top: 10px;
       padding-left: 10px;
     }
    &__answer-title{
      font-weight: bold;
     }
  }


  /*** new post ***/
  &__new {
    & .form-input--textarea {
      min-height: 150px;
      margin-bottom: 10px;
      padding: 13px 18px;
    }

    &-header {
      margin-bottom: 20px;
    }
    &-controls {
      display: flex;
      justify-content: space-between;

      &-btn {
        max-width: 375px;
      }
      &-add {
        display: flex;
        padding-top: 8px;
      }
    }
  }
}

.add {
  &__video {
    margin-left: 38px;

    &-label {
      position: relative;
      display: flex;
      cursor: pointer;
      overflow: hidden;
    }
    &-input {
      opacity: 0;
      visibility: hidden;
      position: absolute;
      left: 0;
      right: 0;
    }
    &-icon {
      width: 19px;
      height: 15px;
      margin-right: 11px;
      margin-top: 2px;
      transition: fill .45s;
    }
    &-text {
      transition: color .35s;
    }
    &-label:hover &-text {
      color: #ff7600
    }
    &-label:hover &-icon {
      fill: #ff7600
    }
  }

  &__image {

    &-label {
      position: relative;
      display: flex;
      cursor: pointer;
      overflow: hidden;
    }
    &-input {
      opacity: 0;
      visibility: hidden;
      position: absolute;
      left: 0;
      right: 0;
    }
    &-icon {
      width: 20px;
      height: 15px;
      margin-right: 11px;
      margin-top: 2px;
      transition: fill .45s;
    }
    &-text {
      transition: color .35s;
    }
    &-label:hover &-text {
      color: #ff7600
    }
    &-label:hover &-icon {
      fill: #ff7600
    }
  }
}

/* –––––––––––––––––––––––––––– */
/* –––––––– BIG DESKTOP ––––––– */
/* –––––––––––––––––––––––––––– */
@media (min-width: 1280px) {

  .forum {
    &__list {
      margin-top: 18px;
    }
    &-item {
      &__name {
        padding-right: 30px;
      }
      &__themes-amount {
        padding-top: 14px;
      }
      &__messages-amount {
        padding-top: 14px;
      }
    }

    &__summary {
      margin-top: -2px;
    }

    /*** themes ***/
    &__controls {
      padding-top: 18px;
      padding-bottom: 8px;
    }

    /*** post ***/
    &__post-wrapper {
      padding-top: 20px;
      margin-bottom: 30px;
    }
    &__new {
      margin-bottom: 12px;
    }
  }

}

/* –––––––––––––––––––––––––––– */
/* ––––––– SMALL DESKTOP –––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 1279px) {

  .forum {
    &__list {
      margin-top: 10px;
    }
    &-item {
      &__themes-amount {
        padding-top: 18px;
      }
      &__messages-amount {
        padding-top: 18px;
      }
    }

    &__summary {
      padding-top: 2px;
    }

    /*** themes ***/
    &__controls {
      padding-top: 10px;
      padding-bottom: 15px;
    }

    /*** post ***/
    &__post-wrapper {
      padding-top: 9px;
      margin-bottom: 31px;
    }

    &__new {
      margin-bottom: 25px;
    }
  }

}

/* –––––––––––––––––––––––––––– */
/* –––––––––– TABLET –––––––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 1023px) {

  .forum {
    &__list {
      margin-top: 12px;
    }

    &__pagination {
      padding-bottom: 40px;
    }

    /*** post ***/
    &__post-wrapper {
      padding-top: 20px;
    }
    &-post {
      &__avatar {
        margin-right: -27px;
      }
      &__body {
        margin-left: 20px;
      }
    }
    &__pagination {
      &--post {
        margin-bottom: 10px;
      }
    }
    &__new {
      margin-bottom: 30px;

      &-controls-add {
        width: 220px;
        flex-direction: column;
        padding-top: 0;
      }
    }
  }

  .add {
    &__image {
      margin-top: -1px;
    }
    &__video {
      margin-left: 0;
      margin-top: 7px;
    }
  }

}


/* –––––––––––––––––––––––––––– */
/* –––––––––– MOBILE –––––––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 767px) {

  .forum {
    &__list {
      margin-top: 6px;
    }
    &-item {
      padding-bottom: 35px;
      &--header {
        padding-bottom: 0;
      }

      &--theme {
        padding-bottom: 30px;

        .forum-item {
          &__name {
            padding-top: 4px;
          }
          &__title {
            line-height: 16px;
          }
        }
      }
    }
    &__summary-info {
      flex-wrap: wrap;
    }
    &__controls {
      padding-bottom: 27px;
    }
    &__select {
      flex-direction: column;
      margin-bottom: 20px;

      &-label {
        margin-right: 0;
        margin-top: 6px;
        margin-bottom: 11px;
      }
    }

    &__pagination {
      padding-bottom: 60px;
      margin-top: -5px;
    }

    /*** post ***/
    &__post-wrapper {
      padding-top: 12px;
      margin-bottom: 27px;
    }
    &-post {
      padding-bottom: 50px;
      &__avatar {
        margin-right: 0;
        width: 74px;
      }
      &__body {
        margin-left: -13px;
        margin-right: -13px;
        padding-left: 0;
      }
      &__author {
        line-height: 15px;
        position: absolute;
        top: -65px;
        left: 100px;
      }
      &__text {
        padding-top: 1px;
        padding-right: 0;
      }
      &__reply {
        right: auto;
        top: -32px;
        left: 100px;
      }
      &__answer-title{
          top: 10px;
          position: relative;
        }
    }

    &__pagination {
      padding-bottom: 39px;
    }

    &__new {
      margin-bottom: 60px;
      padding-bottom: 85px;
    }
    &__new-controls {
      display: block;
      position: relative;

      &-add {
        width: 100%;
        padding-top: 4px;
      }
      &-btn {
        position: absolute;
        left: 0;
        right: 0;
        top: 70px;
        max-width: 100%;

      }
    }
  }

}
