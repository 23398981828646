.brand {
  &__image {
    margin-top: 4px;
    height: 250px;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  &__logo {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    margin-top: 50px;

    & img {
      max-height: 100%;
      width: auto;
    }
  }

  &__text {
    font-size: 16px;
    color: #545454;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 75px;
    position: relative;

    /*&:before {
      content: '';
      position: absolute;
      top: -20px;
      left: 50%;
      margin-left: -20px;
      width: 40px;
      height: 2px;
      background: #000;
    }*/
  }
}

/* –––––––––––––––––––––––––––– */
/* ––––––– SMALL DESKTOP –––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 1279px) {

  .brand {
    &__logo {
      margin-top: 40px;
    }
    &__text {
      margin-bottom: 55px;
    }
  }

}

/* –––––––––––––––––––––––––––– */
/* –––––––––– TABLET –––––––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 1023px) {

  .brand {
    &__logo {
      margin-top: 45px;
    }
  }

}


/* –––––––––––––––––––––––––––– */
/* –––––––––– MOBILE –––––––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 767px) {
  .brand {
    &__image {
      margin-top: 0;
    }
    &__logo {
      margin-top: 55px;
    }
    &__text {
      margin-top: 30px;
      margin-bottom: 55px;
    }
  }
}
