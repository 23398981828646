.account-profile {
  &__section-heading {
    margin: 21px 0 19px;
    font-size: 16px;

    &--small {
      font-size: 14px;
      margin: 22px 0 19px;
    }
  }

  &__form {
    margin-bottom: 80px;
    font-size: 13px;
  }

  &__personal,
  &__password {
    padding-bottom: 16px;
  }
  &__addresses {
    padding-bottom: 26px;
  }
  &__btns-area {
    padding-top: 14px;
  }
  &__button {
    margin-top: 25px;
    margin-bottom: 25px;
  }
}



/* –––––––––––––––––––––––––––– */
/* –––––––––– MOBILE –––––––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 767px) {

  .account-profile {

    &__addresses {
      padding-bottom: 38px;
    }
  }

}
