.filter_item {
  width: 100%;
  height: 45px;
  position: relative;

    /*&--sort {
    margin: 0;
    flex: none;
    width: 179px;
    height: 25px;

        &:after {
        position: absolute;
        right: 0;
        top: 0;
        content: '';
        display: block;
        width: 1px;
        height: 100%;
        background: #ededed;
        }
    }
    &--clear {
      font-size: 11px;
      line-height: 43px;
      text-transform: uppercase;
      font-weight: 600;
      margin: 0 10px;
      opacity: .75;
      & .icon-cart-cross {
        margin: -2px 5px 0 0;
      }
    }*/

  & .trigger {
    width: auto;
    height: 45px;
    padding: 0 38px 0 18px;
    background: #fff;
    box-shadow: inset 0 0 0 1px #ececec;
    cursor: pointer;
    position: relative;

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      margin-top: -1px;
      right: 14px;
      width: 0;
      height: 0;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 4px solid #474747;
      border-bottom: 0 solid #474747;
    }
  }

  & .filter-clear {
    display: none;
    border: none;
    outline: none;
    width: 43px;
    height: 43px;
    position: absolute;
    top: 1px;
    right: 1px;
    background: #fff;
    justify-content: center;
    align-items: center;

    &__icon {
      width: 8px;
      height: 8px;
    }
  }

  &.current .filter-clear {
    display: flex;
  }

  & .f_value {
    font-size: 13px;
    line-height: 45px;
    color: #545454;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & .dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 100%;
    margin-top: -1px;
    background: #fff;
    box-shadow: inset 0 0 0 1px #ececec;
    z-index: 2;
  }
}
