.address-actions {
  min-height: 35px;
  display: flex;
  justify-content: space-around;
  align-items: center;

  &__add {
    margin-right: auto;
    margin-top: 14px;
    margin-left: 20px;
    line-height: 18px;
    border-bottom: 1px solid #666666;
    transition: color .35s, border-color .35s;
    position: relative;

    &-icon {
      width: 9px;
      height: 9px;
      fill: #474747;
      transition: fill .45s;
      position: absolute;
      left: -20px;
      top: 5px;
    }

    &:hover {
      color: #ff7600;
      border-color: #ff7600;
    }
    &:hover &-icon {
      fill: #ff7600;
    }
  }
}

/* –––––––––––––––––––––––––––– */
/* –––––––––– MOBILE –––––––––– */
/* –––––––––––––––––––––––––––– */
@media (max-width: 767px) {
  .address-actions {

    &__add,
    &__edit {
      margin-left: 0;
      margin-top: 12px;
    }
    &__add {
      float: right;
    }
    &__edit {
      border-bottom: 1px solid #666666;
      transition: color .35s, border-color .35s;

      &:hover {
        color: #ff7600;
        border-color: #ff7600;
      }
    }
  }
}
